.tile {
    position: relative;
    display: flex;
	align-items: flex-end;
	width: 100%;
	border-radius: $border-radius;
	overflow: hidden;
	color: white;
	text-decoration: none;

	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 260px;
		background: linear-gradient(to top,  rgba(0,10,24,1) 0%,rgba(0,21,50,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		opacity: .75;
		transition: opacity .3s;
	}
}
body:not(.mobile) {
	.tile {
		&:hover {
			&:after {
				opacity: 1;
			}
			.tile__link__arrow {
				transform: translateX(1.2rem);
			}
		}
	}
}

.tiles {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
}
.tile-container {
    flex: 0 0 100%;
	max-width: 100%;
}

.tile__body {
	position: relative;
    z-index: 10;
    padding-bottom: 3rem;
    padding-left: 3rem;
	padding-right: 3rem;
	width: 100%;
}

.tile__title {
	@include font-extralight();
	font-size: 3.4rem;
	margin-bottom: 2.1rem;
	@include media-breakpoint-up(lg) {
		font-size: 4rem;
	}
}
.tile__link {
	@include font-regular();
	font-size: 1.4rem;
	text-transform: uppercase;
	line-height: 1.571428571428571;
	letter-spacing: 0.2em;
	display: flex;
	align-items: center;
	.tile__link__text {
		display: block;
		padding-right: 3.2rem;
	}
	.tile__link__arrow-container {
		flex-grow: 1;
	}
	.tile__link__arrow {
		position: relative;
		width: 20px;
		height: 20px;
		background-repeat: no-repeat;
		background-position: center left;
		transition: transform .3s $thibault-swing;
		background-image: url("#{$caret}");
		display: block;
	}
}