$booking-bar-border 				: rgba(210, 213, 218, .75);
$inner-modal-header-height 			: 7.6rem;
$inner-modal-footer-height 			: 7.8rem;

.booking-bar {
	.col-16 {
		min-height: 0;
	}
}
.booking-bar-container {
	will-change: opacity;
	transition: opacity .5s $thibault-swing;
	opacity: 1;
	visibility: visible;
	position: absolute;
	top: -86px;
	left: $grid-gutter-width / 2;
	right: $grid-gutter-width / 2;
	height: 136px;
	border-radius: $border-radius;
	box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.08);
	background-color: $white;
	z-index: 5;
	margin-left: ($grid-gutter-width / 2) * -1;
	margin-right: ($grid-gutter-width / 2) * -1;
	@include media-breakpoint-up(sm) {
		top: -43px;
		height: 86px;
	}
	@include media-breakpoint-up(xl) {
		top: -48px;
		height: 96px;
	}
	@include media-breakpoint-up(md) {
		// width: calc(100% + #{$grid-gutter-width} / 2);
		margin-left: $grid-gutter-width * -1;
		margin-right: $grid-gutter-width * -1;
	}
	@include media-breakpoint-up(lg) {
		margin-right: 0;
	}
}
.booking-bar__form {
	display: flex;
	width: 100%;
	height: 136px;
	background-color: $white;
	border-radius: $border-radius;
	// box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.4);
	flex-wrap: wrap;
	@include media-breakpoint-up(sm) {
		flex-wrap: nowrap;
		height: 86px;
		border-radius: $border-radius;
	}
	@include media-breakpoint-up(xl) {
		height: 96px;
	}
}
.booking-bar__item {
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 0 0 50%;
	max-width: 50%;
	height: 86px;
	padding-left: 1.4rem;
	&.is-fade-out {
		@media (max-width: 576px) {
			background-color: #EBEAEA;
			.booking-bar__input-container {
				opacity: .5;
			}
		}
	}
	&.is-invalid {
		color: #EE495D;
	}
	@include media-breakpoint-up(sm) {
		flex: 0 0 calc(50% - 43px);
		max-width: calc(50% - 43px);
		padding-left: 3rem;
		height: 86px;
	}
	@include media-breakpoint-up(md) {
		padding-left: 4rem;
	}
	@include media-breakpoint-up(xl) {
		height: 96px;
		flex: 0 0 calc(50% - 48px);
		max-width: calc(50% - 48px);
	}
	& + .booking-bar__item {
		border-left: 1px solid $booking-bar-border;
	}
	&.no-border {
		border: 0 !important;
	}
	label {
		display: block;
		margin-bottom: .8rem;
		font-size: 1.4rem;
		font-weight: 200;
	}
}
.booking-bar__button {
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 100%;
	max-width: 0 0 100%;
	height: 50px;
	background-color: $primary;
	border: 0;
	outline: 0;
	border-radius: 0 0 $border-radius $border-radius;
	color: $j-white;
	cursor: pointer;
	transition: background-color .3s $thibault-swing;
	@include media-breakpoint-up(sm) {
		width: 86px;
		height: 86px;
		flex: 0 0 86px;
		max-width: 86px;
		border-radius: 0 $border-radius $border-radius 0;
	}
	@include media-breakpoint-up(xl) {
		flex: 0 0 96px;
		max-width: 96px;
		width: 96px;
		height: 96px;
	}
}
body:not(.mobile) {
	.booking-bar__button {
		&:hover {
			background-color: lighten($primary, 5%);
			.booking-bar__button__icon {
				transform: translateX(5px);
			}
		}
	}
}
.booking-bar__button-text {
	text-transform: uppercase;
	font-size: 1.4rem;
	letter-spacing: 0.2em;
	display: inline-block;
	padding-top: 1px;
	padding-right: 5px;
	@include media-breakpoint-up(sm) {
		display: none;
	}
}
.booking-bar__button__icon {
	display: inline-block;
	background-image: url("#{$caret}");
	background-size: 7px 13px;
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-position: center center;
	opacity: .9;
	transition: transform .15s $thibault-swing;
	@include media-breakpoint-up(sm) {
		background-size: 10px 17px;
	}
}

.booking-bar__input-container {
	position: relative;
}
.booking-bar__input-icon {
	position: absolute;
	top: 50%;
	left: 0;
	width: 2.2rem;
	height: 2.2rem;
	padding-top: 2px;
	opacity: .75;
	transform: translateY(-50%);
	svg {
		width: 2.2rem;
		height: 2.2rem;
	}
}
.booking-bar__input {
	border: 0;
	letter-spacing: 0.2em;
	font-weight: 800;
	font-size: 1rem;
	padding-left: 3.2rem;
	padding-top: .8rem;
	outline: 0;
	box-shadow: none;
	max-width: 100%;
	text-transform: uppercase;
	width: 100%;
	@include media-breakpoint-up(sm) {
		font-size: 1.4rem;
	}
	&.select {
		padding-left: 0;
		background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14 1L7.35555 8L0.999999 0.999999' stroke='%23001532' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ") no-repeat right 1.2rem center/15px 9px;
		cursor: pointer;
		padding-right: 3rem;
		font-size: .9rem;
		@media (min-width: 360px) {
			font-size: 1.1rem;
		}
		@include media-breakpoint-up(sm) {
			padding-right: 4;
			font-size: 1.4rem;
			background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14 1L7.35555 8L0.999999 0.999999' stroke='%23001532' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ") no-repeat right 2rem center/15px 9px;
		}
	}
}


.custom-select-div {
	display: flex;
    padding-top: 0;
    height: 26px;
    align-items: center;
}
.select-custom-value-text {
	margin-top: 6px;
}
.booking-bar__desktop-options {
	position: absolute;
	top: 100%;
	left: 0;
	right: -100%;
	display: block;
	border-radius: 0 0 $border-radius $border-radius;
	border-top: 1px solid rgba(210, 213, 218, .75);
	box-shadow: 0px 20px 34px 0px rgba(0,0,0,0.08);
	background-color: white;
	transition: all .3s $thibault-swing;
	opacity: 0;
	visibility: 1;
	pointer-events: none;
	@include media-breakpoint-up(sm) {
		right: 0;
	}

	&.is-shown {
		opacity: 1;
		visibility: 1;
		pointer-events: auto;
	}
	ul {
		list-style-type: none;
		padding-left: 0;
	}
	li {
		padding: 2.8rem 2.4rem 2.8rem 3rem;
		cursor: pointer;
		letter-spacing: 0.2em;
		font-weight: 800;
		font-size: 1.2rem;
		text-transform: uppercase;
		border-bottom: 1px solid rgba(0, 0, 0, .1);
		margin: 0;
		@include media-breakpoint-up(sm) {
			font-size: 1.4rem;
		}
		background-color: rgba(0, 21, 50, 0);
		transition: background-color .25s ease;
		&:hover {
			background-color: rgba(0, 21, 50, .1);
		}
		@include media-breakpoint-up(md) {
			padding: 3.2rem 2.4rem 3.2rem 4rem;
		}
		&:last-child {
			border-bottom: 0;
		}
	}
}

// Accomodation specific booking bar
.col--booking-bar{
	@media (min-width: 680px) and (max-width: 768px) {
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}
}
.booking-bar-container--acc {
	@include media-breakpoint-down(md) {
		top: -169px !important;
	}

	@media (min-width: 660px) {
		margin-left: 0;
		margin-right: 0;
	}
	@include media-breakpoint-up(lg) {
		margin-left: 0;
	}
	@include media-breakpoint-up(xl) {
		margin-left: -3rem;
		margin-right: -3rem;
	}
	
	.booking-bar__item + .booking-bar__item  {
		@include media-breakpoint-down(sm) {
			border-left: 0;
		}
	}
	.booking-bar__form {
		flex-wrap: wrap !important;
		@include media-breakpoint-down(md) {
			height: 171px;
		}
		@include media-breakpoint-up(lg) {
			flex-wrap: nowrap !important;
		}
	}
	.booking-bar__input {
		font-size: 1.2rem;
		@include media-breakpoint-up(xl) {
			font-size: 1.4rem;
		}
	}
	@include media-breakpoint-up(md) {
		top: -48px;
	}
	.booking-bar__item {
		@include media-breakpoint-down(md) {
			order: 2;
			height: 84px;
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
	.booking-bar__button-text {
		display: block;
		@include media-breakpoint-up(sm) {
			display: block;
		}
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}
	.booking-bar__button {
		order: 4;
		@include media-breakpoint-down(md) {
			flex: 0 0 100%;
			max-width: 100%;
			height: 50px;
		}
	}
	.booking-bar__item--room {
		@include media-breakpoint-down(md) {
			order: 1;
			flex: 0 0 100%;
			max-width: 100%;
			border-bottom: 1px solid $booking-bar-border;
		}
	}
	
	.booking-bar__form {
		flex-wrap: wrap;
		@include media-breakpoint-up(md) {
			flex-wrap: nowrap;
		}
	}
	.booking-bar__item {
		@include media-breakpoint-up(lg) {
			flex: 0 0 calc(33.3333% - 28px);
			max-width: calc(33.3333% - 28px);
		}
		
		@include media-breakpoint-up(xl) {
			flex: 0 0 calc(33.3333% - 32px);
			max-width: calc(33.3333% - 32px);
		}
	}
}

.booking-bar-container--is-promo-code {
	.room-selector {
		bottom: 100%;
		width: 200%;
		left: 0;
		@include media-breakpoint-up(lg) {
			width: 100%;
		}
	}
	.booking-bar__item {
		@include media-breakpoint-down(md) {
			order: 1;
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
	.booking-bar__button-text {
		display: block;
		@include media-breakpoint-up(sm) {
			display: block;
		}
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}
	.booking-bar__button {
		order: 4;
		@include media-breakpoint-down(md) {
			flex: 0 0 100%;
			max-width: 100%;
			height: 50px;
		}
	}
	.booking-bar__item--room,
	.booking-bar__item--promo-code {
		@include media-breakpoint-down(md) {
			flex: 0 0 50%;
			max-width: 50%;
			border-bottom: 0;
			border-top: 1px solid $booking-bar-border;
		}
	}
	
	.booking-bar__form {
		flex-wrap: wrap;
		@include media-breakpoint-up(md) {
			flex-wrap: nowrap;
		}
	}

	.booking-bar__item {
		@include media-breakpoint-up(lg) {
			padding-left: 2.8rem;
			flex: 0 0 calc(26% - 20px);
			max-width: calc(26% - 20px);
			&--room {
				flex: 0 0 calc(30% - 20px);
				max-width: calc(30% - 20px);
			}
			&--promo-code {
				flex: 0 0 calc(18% - 20px);
				max-width: calc(18% - 20px);
				padding-right: 2.8rem;
			}
		}
		
		@include media-breakpoint-up(xl) {
			flex: 0 0 calc(26% - 24px);
			max-width: calc(26% - 24px);
			&--room {
				flex: 0 0 calc(30% - 24px);
				max-width: calc(30% - 24px);
			}
			&--promo-code {
				flex: 0 0 calc(18% - 24px);
				max-width: calc(18% - 24px);
				padding-right: 2.8rem;
			}
		}
		&--promo-code {
			padding-right: 1.4rem;
			&:after {
				content: '';
				width: 1px;
				height: 56px;
				background-color: #D2D5DA;
				position: absolute;
				left: -1px;
				top: 50%;
				margin-top: -28px;
				opacity: .75;
			}
			@include media-breakpoint-up(sm) {
				padding-right: 3rem;
			}
		}
		@include media-breakpoint-down(md) {
			& + .booking-bar__item {
				border-left: 0;
			}
		}
	}
	.booking-bar__button {
		@include media-breakpoint-up(lg) {
			flex: 0 0 80px;
    		max-width: 80px;
		}
		@include media-breakpoint-up(xl) {
			flex: 0 0 96px;
    		max-width: 96px;
		}
	}
}

.booking-bar__item--room {
	svg {
		width: 2.4rem;
		height: 2.4rem;
	}
	cursor: pointer;
	label {
		pointer-events: none;
	}
}

.room-selector {
	position: absolute;
	bottom: calc(100% - 4px);
	right: 0;
	max-height: 0;
	width: 100%;
	border-radius: 5px 5px 0 0;
	background-color: white;
	border-bottom: 1px solid rgba(210, 213, 218, 0.75);

	overflow: hidden;
	max-height: 0;
	opacity: 1;
	visibility: hidden;
	transition: all .3s $thibault-swing;
	z-index: 1;
	@include media-breakpoint-up(lg) {
		bottom: 100%;
	}
	
	.is-show & {
		max-height: 600px;
		opacity: 1;
		visibility: visible;
	}
}

.room-selector__scroll {
	max-height: 30vh;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	padding: 1.6rem 1.6rem 0 1.6rem;
	@media (min-height: 500px) {
		max-height: 35vh;
	}
	@media (min-height: 600px) {
		max-height: 45vh;
	}
	@include media-breakpoint-up(sm) {
		padding: 1.6rem 3.2rem 0 3.2rem;
	}
	@include media-breakpoint-up(lg) {
		padding: 1.6rem 1.6rem 0 1.6rem;
	}
	@include media-breakpoint-up(xl) {
		padding: 1.8rem 3.2rem 0 3.2rem;
	}
}
.room__add {
	padding: 0 1.6rem;
	@include media-breakpoint-up(sm) {
		padding: 0 3.2rem;
	}
	@include media-breakpoint-up(lg) {
		padding: 0 1.6rem;
	}
	@include media-breakpoint-up(xl) {
		padding: 0 3.2rem;
	}
}

.room__label {
	position: relative;
	letter-spacing: 0.2em;
	font-weight: 800;
	font-size: 1.2rem;
	max-width: 100%;
	text-transform: uppercase;
	width: 100%;
	margin-bottom: 1rem;
	padding-top: 1.9rem;
	padding-bottom: 1.8rem;
	margin-bottom: 0;
	@include media-breakpoint-up(sm) {
		font-size: 1.4rem;
	}
	
	&:after {
		content: '';
		width: 13px;
		height: 7px;
		margin-top: -4px;
		position: absolute;
		top: 50%;
		right: 12px;
		background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='13' height='7' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12 1L6.37778 6L1 1' stroke='%23001532' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
		background-repeat: no-repeat;
		background-position: center right;
		display: none;
	}
	.has-multiple & {
		&:after {
			display: block;
		}
	}
}
.room__item-section {
	display: none;
	padding-bottom: 1.4rem;
}
.room__item {
	&.is-active {
		.room__item-section {
			display: block;
		}
		.room__label {
			&:after {
				transform: rotate(-180deg);
			}
		}
	}
	& + .room__item {
		border-top: 1px solid rgba(210, 213, 218, 1);
	}
}

.room__row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: .6rem;
	padding-bottom: .6rem;
}
.room__row-title {
	@include font-light();
	font-size: 1.6rem;
	flex: 0 0 112px;
	max-width: 112px;
	.small {
		font-size: 1.3rem;
		opacity: .5;
	}
}

.room__config {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	padding-left: 1rem;
	max-width: 160px;
	input {
		border: 0;
		outline: 0;
		text-align: center;
		width: 3.6rem;
		color: $primary;
		font-size: 1.8rem;
	}
	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 3.6rem;
		height: 3.6rem;
		border-radius: 50%;
		border-width: 1px;
		color: $primary;
		border: 1px solid $primary;
		background-color: transparent;
		cursor: pointer;
		transition: all .2.5s ease;
		&:focus {
			outline: 0;
		}
		svg {
			width: 1.6rem;
			height: 1.6rem;

			@include media-breakpoint-up(md) {
				width: 1.4rem;
				height: 1.4rem;
			}
			@include media-breakpoint-up(xl) {
				width: 1.6rem;
				height: 1.6rem;
			}
		}
		&:disabled,
		&.disabled {
			color: #D2D5DA;
			border-color: #D2D5DA;
			pointer-events: none;
		}
		&:hover,
		&:focus {
			color: white;
			background-color: $primary;
		}
	}
}

@media all and (-ms-high-contrast:none) {
	*::-ms-backdrop,
	.room__config {
		 max-width: none;
	}
	*::-ms-backdrop,
	.room__row-title {
		 min-width: 50%;
	}
}

.room__remove {
	visibility: hidden;
	opacity: .5;
	&:hover,
	&:focus {
		opacity: 1;
	}
	.is-active & {
		visibility: visible;
	}
}

.button--link-small {
	@include font-bold();
	color: $body-colour;
	font-size: 1.2rem;
	text-decoration: underline;
	text-transform: none;
	letter-spacing: normal;

}

.room__add {
	padding-top: 2.4rem;
	padding-bottom: 2.4rem;
}
.btn-mute {
	background-color: rgba(210, 213, 218, .5);
	outline: 0;
	border: 0;
	height: 5rem;
	border-radius: $border-radius;
	width: 100%;
	text-transform: uppercase;
	font-size: 1.2rem;
	font-weight: 800;
	letter-spacing: 0.2em;
	transition: all .3s ease;
	cursor: pointer;
	@include media-breakpoint-up(xl) {
		font-size: 1.4rem;
	}
	&:hover,
	&:focus {
		background-color: rgba(210, 213, 218, 1);
	}
}

// BOOKING MODAL
.room__book-now {
	padding-top: 4rem;
	padding-bottom: 2rem;
	.button--button {
		color: white;
		&:before {
			width: 100%;
		}
	}
}

.bm-is-open {
	overflow: hidden;
	height: 100%;
}

.bm__inner-modal {
	display: flex;
	flex-direction: column;
	@include media-breakpoint-up(md) {
		display: none;
	}
}

.bm__inner-modal__footer {
	flex-grow: 1;
    display: flex;
	align-items: flex-end;
	padding-left: 3rem;
	padding-right: 3rem;
	padding-bottom: 2.4rem;
}


.bm__inner-modal__header {
	position: relative;
	height: $inner-modal-header-height;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $body-colour;
	font-size: 1.8rem;
	@include font-light();
}
.bm__inner-modal__close {
	position: absolute;
	left: 30px;
	top: 50%;
	transform: translateY(-50%);
	color: $body-colour;
	svg {
		transform: rotate(-180deg);
		width: 1.6rem;
		height: 1.6rem;
	}
}

.bm {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	text-align: center;
	width: 100%;
	font-size: 0;
	@include media-breakpoint-up(md) {
		.room-selector__scroll {
			max-height: none;
		}
	}
	.mini-clndr,
	.room-selector {
		z-index: 2;
		border-bottom: 0 !important;
		&:before {
			display: none;
		}
	}
	.bm-is-open & {
		display: block;
	}
	.booking-bar__item + .booking-bar__item {
		border-left: 0;
		@include media-breakpoint-up(md) {
			&:after {
				content: '';
				width: 1px;
				height: 56px;
				background-color: #D2D5DA;
				position: absolute;
				left: -1px;
				top: 50%;
				margin-top: -28px;
				opacity: .75;
			}
		}
	}

	// Mobile modal design
	@include media-breakpoint-down(msm) {
		.clndr .day.event:before, .clndr .empty.event:before {
			top: 5px;
			bottom: 5px;
		}
		.bm__scroll {
			padding-top: 2rem !important;
			padding-bottom: 2rem !important;
		}
		.bm__inner-modal {
			background-color: white;
			position: absolute;
			right: 0;
			bottom: 0;
			top: 0;
			width: 0;
			overflow: hidden;
			transition: all .3s $thibault-swing;
			z-index: 1;
		}

		.clndr {
			.controls {
				background-color: #fff;
				padding-left: 1rem;
				padding-right: 1rem;
			}
			.days {
				padding-left: 1rem;
				padding-right: 1rem;
			}
			.day {
				font-size: 1.4rem;
			}
		}

		.bm-modal-is-visible & {
			.bm__inner-modal {
				width: 100%;
			}
		}

		.booking-bar__item {
			position: inherit;
			padding-left: 0;
		}
		.booking-bar__item--room {
			margin-bottom: 1.2rem;
		}
		.l_bm-row {
			&:last-child {
				margin-bottom: 1.2rem;
				border-bottom: 1px solid rgba(210, 213, 218, .7);
			}
		}
		.bm__inner {
			position: relative;
			min-height: 530px;
			padding: 4rem 3rem 9rem 3rem;
			border-radius: 0;	
		}

		.room-selector {
			right: 0;
			left: auto;
			width: 0;
			top: $inner-modal-header-height;
			bottom: $inner-modal-footer-height;
			max-height: none;
		}
		.is-show {
			.room-selector {
				width: 100%;
			}
		}

		.room-selector__scroll {
			max-height: 282px;
			min-height: 282px;
			padding-left: 3rem;
			padding-right: 3rem;
		}
		.room__add {
			padding-left: 3rem;
			padding-right: 3rem;
		}

		.mini-clndr {
			top: $inner-modal-header-height;
			bottom: $inner-modal-footer-height;
			width: 0;
			left: auto;
			right: 0;
			background-color: white;
			max-height: none;
			opacity: 0;
			&.is-shown {
				width: 100% !important;
				opacity: 1;
			}
		}
		.booking-bar__item.is-fade-out {
			background-color: transparent;
		}

		.clndr {
			height: 100%;
			.month {
				font-size: 1.4rem;
			}
			.day-header {
				padding-top: 1rem;
				padding-bottom: 1rem;
			}
			.day {
				height: 4rem;
				align-items: center;
			}
		}
		.clndr-previous-button svg, .clndr-next-button svg {
			width: 2.8rem;
			height: 2.8rem;
		}
		
		.booking-bar__item + .booking-bar__item {
			.mini-clndr {
				width: 0;
				left: auto;
				right: 0;
			}
		}
		.booking-bar__input {
			font-size: 1.4rem;
		}

		.bm__title {
			padding-top: 3rem;
			margin-bottom: 4rem;
		}
		.bm__title__subtext {
			display: block;
			padding-top: 1.3rem;
		}
		.room__book-now {
			position: absolute;
			bottom: 2.4rem;
			left: 3rem;
			right: 3rem;
			padding-bottom: 0;
			.button--button {
				width: 100%;
			}
		}
	}
}
.bm__inner {
	text-align: left;
	padding: 4rem 3rem;
	background-color: #fff;
	border-radius: $border-radius;
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	max-width: 824px;
	position: relative;
	z-index: 2;
	@include media-breakpoint-up(md) {
		background-color: $body-bg;
	}
	@include media-breakpoint-up(xl) {
		padding: 6rem;
	}
}
.bm__scroll {
	position: relative;
	height: 100%;
	overflow: auto;
	padding-top: 20px;
	padding-bottom: 20px;
	@include media-breakpoint-up(md) {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}
	&:after {
		content: '';
		display: inline-block;
		width: 0;
		height: 100%;
		vertical-align: middle;
	}
}

.bm__bg {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 21, 50, .65);
}

.l_bm-grid {
	display: flex;
	flex-direction: column;
	.l_bm-row {
		&:first-child {
			@include media-breakpoint-down(msm) {
				order: 1;
			}
		}
	}
}

.l_bm-row {
	display: flex;
	@include media-breakpoint-up(md) {
		background-color: white;
		border-radius: $border-radius;
		box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.04);
	}
	.booking-bar__item {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.booking-bar__item--room {
		flex: 0 0 100%;
		max-width: 100%;
	}
	& + .l_bm-row {
		@include media-breakpoint-up(md) {
			margin-top: 3rem;
		}
	}
}

.bm__title {
	font-size: 3.6rem;
	@include font-extralight();
	&__text {
		padding-right: 2.6rem;
	}
	&__subtext {
		font-size: 1.4rem;
		@include font-light();
		letter-spacing: -0.01em;
		display: inline-block;
	}
}

.bm__close {
	position: absolute;
	top: 2.2rem;
	right: 1.8rem;
	color: $body-colour;
	border: 0;
	background-color: transparent;
	width: 4rem;
	height: 4rem;
	align-items: center;
	justify-content: center;
	outline: 0 !important;
	cursor: pointer;
	@include media-breakpoint-up(md) {
		top: 2.8rem;
		right: 2.8rem;
	}
	svg {
		width: 1.6rem;
		height: 1.6rem;
	}
}

.bm--is-promo-code {
	.booking-bar__item--promo-code,
	.booking-bar__item--room {
		@include media-breakpoint-up(md) {
			flex: 0 0 50% !important;
			max-width: 50% !important;
		}
	}

	.booking-bar__item--promo-code {
		flex: 0 0 50% !important;
		max-width: 50% !important;
		padding-right: 4rem;
		input {
			width: 18rem;
		}
	}

	.l_bm-row {
		order: 1;
	}

	.l_bm-row--room-and-promo {
		flex-wrap: wrap;
		order: 2;
		@include media-breakpoint-down(md) {
			border-top: 1px solid rgba(210, 213, 218, .3);
			border-bottom: 0 !important;
		}
	}	
}

.booking-bar__item--promo-code {
	input {
		border-bottom: 1px solid #D2D5DA;
		padding-left: 0;
		padding-bottom: .6rem;
		margin-bottom: -.6rem;
		&:focus {
			border-bottom: 1px solid $secondary;
		}
	}
}

// &:after {
// 	content: '';
// 	width: 1px;
// 	height: 56px;
// 	background-color: #D2D5DA;
// 	position: absolute;
// 	left: -1px;
// 	top: 50%;
// 	margin-top: -28px;
// 	opacity: .75;
// }