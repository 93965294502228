// DOTO : move me
// media modal
$media-modal-bg                 : rgba(0, 0, 0, .8);
$media-modal-arrow-bg           : transparent;
$media-modal-arrow-bg-hover     : rgba(0, 2, 50, 0.2);

.media-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: none;

    &.is-visible {
        display: block;
	}
	// DOTO ???
    .swiper-slide {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 1px;
        .content-block__bg {
            display: flex;
            align-items: center;
            justify-content: center;
        }
	}
	// DOTO ???
    .swiper-button {
        display: inline-flex;
        width: 44px;
        height: 44px;
        align-items: center;
        justify-content: center;
        background-image: none;
        color: #fff;
        border-radius: 50%;
        background-color: $media-modal-arrow-bg;
        @include media-breakpoint-up(xl) {
            width: 48px;
            height: 48px;
        }
        @include hover-focus() {
            background-color: $media-modal-arrow-bg-hover;
        }
	}
	// DOTO ???
    .swiper-button-disabled {
        opacity: 0;
	}
	// DOTO ???
    .swiper-button-prev {
        left: -53px;
        @include media-breakpoint-up(xl) {
            left: -63px;
        }
	}
	// DOTO ???
    .swiper-button-next {
        right: -53px;
        @include media-breakpoint-up(xl) {
            right: -63px;
        }
	}
	// DOTO ???
    .swiper-button__icon {
        width: 28px;
        height: 28px;
        @include media-breakpoint-up(xl) {
            width: 32px;
            height: 32px;
        }
    }
}

.media-modal__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $media-modal-bg;
}

.media-modal__close {
    position: absolute;
    top: 8px;
    right: 8px;
	z-index: 5;
	color: $white;
	background-color: rgba(0, 2, 50, .2);
	border-radius: 50%;
	border: 0;
	outline: 0;
	width: 6.4rem;
	height: 6.4rem;
	cursor: pointer;
    @include media-breakpoint-up(xl) {
        top: 15px;
        right: 15px;
    }
}

.media-modal-is-visible {
    overflow: hidden;
    height: 100%;
    .main {
        .swiper-button {
            opacity: 0;
        }
    }
}

.l-media-modal__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 4.8rem 0;
    @include media-breakpoint-up(md) {
        padding: 7.6rem 6rem;
    }
    @include media-breakpoint-up(xl) {
        padding: 9rem 8rem;
    }
}

.media-modal__carousel {
    height: 100%;
    overflow: visible;
}

// Image
.media-modal__image-container {
    position: relative;
    max-height: 100%;
    max-width: 100%;
    margin-bottom: 0;
    &:before {
        @include media-breakpoint-up(md) {
            content: '';
            display: block;
            background: linear-gradient(0deg, rgba(0,5,48,0.27) 0%, rgba(0,3,34,0) 100%);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 44%;
            z-index: 0;
        }
    }
}

.media-modal__image {
    .content-block__bg & {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: calc(100vh - 96px);
        @include media-breakpoint-up(md) {
            max-height: calc(100vh - 152px);
        }
        @include media-breakpoint-up(xl) {
            max-height: calc(100vh - 180px);
        }
    }
}

// .lazyload + .media-modal__image-caption {
//     opacity: 0;
//     color: transparent;
// } 
.media-modal__image-caption {
    padding: 1rem 2.4rem;
    color: #fff;
    @include font-bold();
    font-size: 1.4rem;
    line-height: 1;
    text-align: center;
    transition: all 1.2s ease;
    @include media-breakpoint-down(sm) {
        color: #fff !important;
    }
    @include media-breakpoint-up(md) {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 1rem 3rem;
        text-align: left;
    }

    // DOTO - remove later ???
    // &:before {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     width: 40px;
    //     height: 28px;
    //     bottom: 0;
    // }
}

.media-modal__video-container {
    @include responsive-div(56.25%);
    height: auto;
    max-width: 100%;
    max-height: calc(100vh - 96px);
    overflow: hidden;
    @include media-breakpoint-up(md) {
        max-height: calc(100vh - 152px);
    }
    @include media-breakpoint-up(xl) {
        max-height: calc(100vh - 196px);
    }
}

.media-modal__video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.media-modal__slide__loader {
    color: $primary;
    @include spinner(30px);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}