.social {
    display: flex;
    justify-content: center;
    padding-bottom: 4.2rem;
    @include media-breakpoint-up(md) {
        justify-content: flex-end;
        padding-bottom: 0;
    }
}
.social__link {
    display: block;
    margin-left: 1.4rem;
    color: inherit;
    transition: opacity .35s ease;
    &:hover,
    &:focus {
        opacity: .7;
    }
    svg {
        flex-shrink: 0;
    }
}