$video-icon-colour: blue;

// Video
.embed-responsive {
    position: absolute !important;
    display: block;
    padding: 0;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    .embed-responsive-item,
    iframe,
    embed,
    object,
    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  
  .embed-responsive-16by9,
  .ratio-16by9 {
      position: relative;
  
      &:after {
          display: block;
          content: '';
          padding-bottom: 56.21%;
      }
  }
  
  .vimeo-video-placeholder,
  .video-placeholder {
      
    position: relative;
    .center-wrapper {
        height: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    &:hover {
        cursor: pointer;
    }
    .btn-play {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;

        background-color: $video-icon-colour;
        color: $white;

        border-radius: 50%;
        transition: all .25s ease;
        @include hover-focus() {
            background-color: darken($video-icon-colour, 5%);
        }
        &__icon {
            width: 32px;
            height: 32px;
            margin-left: 2px;
        }
        &__text {
            margin-top: 2px;
        }
    }
}