.v_4-column-tiles {
	.container {
		@include media-breakpoint-up(xxl) {
			max-width: 90vw;
		}
		@media (min-width: 2200px) {
			max-width: 2000px;
		}
	}
	.tiles {
		margin-left: -15px;
		margin-right: -15px;
	}
	.tile-container {
		padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 1.5rem;
	    @include responsive-div(76%);
	    @include media-breakpoint-up(sm) {
		    @include responsive-div(56%);
	    }
		@media (max-width: 659px) {
			transform: none !important;
			.tile {
				position: absolute;
				top: 0;
				left: 0; 
				bottom: 0;
			}
		}
		@media (min-width: 660px) {
			flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: 0;
			@include responsive-div(0);
		}
		@include media-breakpoint-up(xl) {
			flex: 0 0 25%;
			max-width: 25%;
		}
		@include media-breakpoint-up(xxl) {
			.tile {
				@include responsive-div(140%);
				height: auto;
			}
		}
	}
	.tile {
		@media (min-width: 660px) {
			height: 400px;
		}
		@include media-breakpoint-up(lg) {
			height: 460px;
		}
	}
	.tile__body {
		@include media-breakpoint-up(xxl) {
			padding-bottom: 4rem;
			padding-left: 4rem;
			padding-right: 4rem;
		}
	}
}


// Different versions
.v_4-column-tiles--01 {
	.tile-container {
		&:first-child {
			@media (min-width: 660px) {
				padding-top: 3.6rem;
			}
			@include media-breakpoint-up(xl) {
				padding-top: 5rem;
			}
		}
		&:nth-child(2) {
			@media (min-width: 660px) {
				padding-top: 8.4rem;
			}
			@include media-breakpoint-up(xl) {
				padding-top: 0;
			}
		}
		&:nth-child(3) {
			@media (min-width: 660px) {
				padding-top: 9.6rem;
			}
			@include media-breakpoint-up(xl) {
				padding-top: 3rem;
			}
		}
		&:nth-child(4) {
			@media (min-width: 660px) {
				padding-top: 5rem;
			}
			@include media-breakpoint-up(xl) {
				padding-top: 11rem;
			}
		}
	}
}

.v_4-column-tiles--02 {
	.tile-container {
		&:first-child {
			@media (min-width: 660px) {
				padding-top: 8rem;
			}
			@include media-breakpoint-up(xl) {
				padding-top: 6rem;
			}
		}
		&:nth-child(2) {
			@media (min-width: 660px) {
				padding-top: 8.4rem;
			}
			@include media-breakpoint-up(xl) {
				padding-top: 20rem;
			}
		}
		&:nth-child(3) {
			@media (min-width: 660px) {
				padding-top: 5.6rem;
			}
			@include media-breakpoint-up(xl) {
				padding-top: 0;
			}
		}
		&:nth-child(4) {
			@media (min-width: 660px) {
				padding-top: 5rem;
			}
			@include media-breakpoint-up(xl) {
				padding-top: 20rem;
			}
		}
	}
}

.v_4-column-tiles--03 {
	.tile-container {
		&:first-child {
			@media (min-width: 660px) {
				padding-top: 8.6rem;
			}
			@include media-breakpoint-up(xl) {
				padding-top: 6rem;
			}
		}
		&:nth-child(2) {
			@media (min-width: 660px) {
				padding-top: 8.4rem;
			}
			@include media-breakpoint-up(xl) {
				padding-top: 22rem;
			}
		}
		&:nth-child(3) {
			@media (min-width: 660px) {
				padding-top: 14.6rem;
			}
			@include media-breakpoint-up(xl) {
				padding-top: 10rem;
			}
		}
		&:nth-child(4) {
			@media (min-width: 660px) {
				padding-top: 5rem;
			}
			@include media-breakpoint-up(xl) {
				padding-top: 0;
			}
		}
	}
}