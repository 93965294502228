// Image carousel
.image-carousel__image {
    img {
        width: 100%;
        max-width: 100%;
    }
}

// Image
.image-container {
    img {
        width: 100%;
        max-width: 100%;
    }
}