.v_50-50 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    &.is-flip {
        .col-16 {
            order: 2;
            + .col-16 {
                order: 1;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .container {
            max-width: none;
            padding-left: 0;
            padding-right: 0;
            .row {
                margin: 0;
            }
            .col-16 {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.col_50 {
    width: 50%;
    @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        bottom: 0;
    }
    &--content-bg {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
    }
    &--media {
        right: 0;
        z-index: 1;
        @include responsive-div(95.25%);
        @include media-breakpoint-up(md) {
            @include responsive-div(0);
            width: 50%;
            position: absolute;
        }
        .is-flip & {
            left: 0;
        }
    }
}

.v_50-50__content-container {
    padding-top: 7rem;
    padding-bottom: 7rem;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    
    @include media-breakpoint-up(md) {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
    }
    .rte {
        @include media-breakpoint-up(md) {
            max-width: 33.333333vw;
        }
        @include media-breakpoint-up(xl) {
            max-width: 28vw;
        }
        @media (min-width: 1980px) {
            max-width: 25vw;
        }
    }
    .is-flip & {        
        // @include media-breakpoint-up(md) {
        //     margin-left: -4%;
        //     margin-right: -5.5%;
        // }
        // @media (min-width: 1336px) {
        //     margin-left: 5%;
        // }
        // @media (min-width: 1454px) {
        //     margin-left: 50px;
        // }
    }
}

.v_50-50__image-container {
	position: relative;
    @include media-breakpoint-up(md) {
        margin-left: calc((#{$grid-gutter-width} / 2) * -1);
        margin-right: $site-padding-lg * -1;
    }
    @media (min-width: 1320px) {
        margin-right: calc( (100vw - #{$site-width}) / 2 * -1)
    }
	img {
		width: 100%;
		display: block;
	}
}

.aspect-ratio-50-50 {
    opacity: .5;
    @include media-breakpoint-up(md) {
        @include responsive-div(95.25%);
    }
    @include media-breakpoint-up(xl) {
        @include responsive-div(90%);
    }
    @include media-breakpoint-up(xxl) {
        @include responsive-div(84%);
    }
    @include media-breakpoint-up(xxxl) {
        @include responsive-div(78%);
    }
    @media (min-width: 1800px) {
        max-height: 100vh;
    }
}