.btn,
.button {
    .btn__loader-text,
    .btn__loader,
    .button__loader {
        display: none;
    }
    
    &.is-loading {
        pointer-events: none;
        .btn__text,
        .button__text {
            display: none;
        }

        .btn__loader-text,
        .btn__loader,
        .button__loader {
            display: block;
        }
        &:before {
            background-image: none;
        }
    }
    &--has-loader {
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}

@keyframes spinner {
    to {transform: rotate(360deg);}
  }

.btn,
.button {
    &__loader {    
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-bottom: 2px;
        margin-left: 12px;
        position: relative;
        &:before {
            content: '';
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
            border-radius: 50%;
            border: 2px solid transparent;
            border-top-color: currentColor;
            border-bottom-color: currentColor;
            animation: spinner .8s ease infinite;
        }
    }
}

.button--button {
    .button__loader {
        position: absolute;
        right: 15px;
        top: 50%;
        color: white;
        transform: translateY(-50%);
    }
}