.webcams-block {
	display: flex;
	overflow: hidden;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	.content-block__height {
		min-height: 90vh;
		flex: 0 0 0;
		max-width: 0;
	}
	.content-block__content-container {
        position: relative;
        z-index: 3;
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        padding-top: 16rem;
		padding-bottom: 16rem;
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			background: $gradient-overlay-left;
			width: 100%;
			pointer-events: none;
			@include media-breakpoint-up(md) {
				width: 75%;
			}
		}
	}

	.icon-play,
	.icon-pause {
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.is-play {
		.icon-play {
			opacity: 1;
		}
	}
	.is-pause {
		.icon-pause {
			opacity: 1;
		}
	}
	.content-block__content {
		position: relative;
		z-index: 1;
	}
}

.webcams__date {
	font-size: 1.4rem;
	letter-spacing: 0.2em;
	text-transform: uppercase;
	color: rgba(255, 255, 255, .3);
	text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5)
}
.webcams__title {
	font-weight: 200 !important;
	margin-bottom: 2.4rem;
}

// webcam selectors
.webcams__list {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}
.webcam__button-image {
	position: relative;
	width: 100%;
	height: 111px;
	overflow: hidden;
	border-radius: $border-radius;
	img	{
		border-radius: $border-radius;
	}
}

.webcams-scroll {
	margin-right: -80px;
	height: 194px;
	overflow: hidden;
 
	@include media-breakpoint-down(sm) {
		margin-left: -15px;
	}
}

.webcams {
	display: flex;
	margin-left: -15px;
	margin-right: -15px;
	overflow-x: scroll;
	height: 230px;
	white-space: nowrap;
	padding: 5px;
	overflow-y: hidden;
	position: relative;
	z-index: 1;
}
.webcam-container {
	padding-left: 15px;
	padding-right: 15px;
}
.webcam {
	width: 208px;
	padding-bottom: 3rem;
	@include media-breakpoint-up(md) {
		padding-bottom: 5rem;
	}
}

.webcam__button {
	cursor: pointer;
	&:hover,
	&:focus {
		.webcam__button__title {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

.webcam__button__title {
	font-weight: 300;
	font-size: 1.6rem;
	margin-bottom: 1.5rem;
	opacity: 0;
	transform: translateY(100%);
	transition: all .3s $thibault-swing;
	.mobile & {
		opacity: 1;
		transform: translateY(0);
	}
}

.webcam-spacer {
	flex: 0 0 78px;
	height: 10px;
}


.content-block {
	&:first-child {
		.webcams-block {
			.content-block__content-container {
				@include nav-gradient-top();
			}
		}
	}
}