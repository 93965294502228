$form-control-border: rgba(0, 21, 50, .15);
$form-control-height: 4.5rem;

// General form styles
// Remove arrows on number inputs.
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number] {
    -moz-appearance:textfield;
}


@mixin form-control() {
    display: block;
    width: 100%;
    height: $form-control-height;
    border-radius: $border-radius;
    border: 1px solid $form-control-border;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    margin-bottom: 3.2rem;
    background-color: $white;
    transition: border .35s ease;
    font-size: 1.4rem;
    line-height: 1.5;
    &:active,
    &:focus {
        border: 1px solid #2785C6;
        outline: 0;
    }
    .has-error & {
        border: 1px solid $error-colour;
    }
}
// Form styles
.form__control {
    @include form-control();
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    @include form-control();
    box-shadow: 0 0 0 1000px #ffffff inset;
}

.form__label {
    display: block;
    @include font-label();
    margin-bottom: .1rem;
}

textarea.form__control {
    height: 198px;
    resize: none;
}

select {
    word-wrap: normal;
}
.custom-select {
    -webkit-appearance: none;
    appearance: none;
    vertical-align: middle;
    background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath opacity='0.55' d='M1 0.749023L9 8.74902L17 0.749023' stroke='%23001532'/%3e%3c/svg%3e ") no-repeat right 1.6rem center/18px 10px;
    padding-right: 3.8rem;
}
select::-ms-expand {
    display: none;
}

.d-none{
    display: none !important;
    z-index: 1999;
}

// Help text
.form__help-text {
    @include font-small();
    display: inline-block;
    padding-left: .5rem;
    position: relative;
    bottom: 1rem;
}

.form__label-error {
    @include font-small();
    
    color: $error-colour;
    padding-left: .3rem;
    display: none;
    .has-error & {
        display: inline-block;
    }
}