.v_3-column-tiles {
	.container {
		@include media-breakpoint-up(xxl) {
			max-width: 90vw;
		}
		@media (min-width: 2200px) {
			max-width: 2000px;
		}
	}
	.tiles {
		margin-left: -15px;
		margin-right: -15px;
		@media (min-width: 660px) {
			margin-left: 0;
			margin-right: 0;
		}
		@media (min-width: 840px) {
			margin-left: -8px;
			margin-right: -8px;
		}
	}
	.tile-container {
        margin-bottom: 1.5rem;
	    @include responsive-div(76%);
	    @include media-breakpoint-up(sm) {
		    @include responsive-div(56%);
	    }
	    @media (min-width: 840px) {
		    @include responsive-div(0);
	    }
		@media (max-width: 839px) {
			transform: none !important;
			.tile {
				position: absolute;
				top: 0;
				left: 0; 
				bottom: 0;
			}
		}
			
		@media (min-width: 840px) {
			margin-bottom: 0;
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
			padding-left: 8px;
			padding-right: 8px;
		}
		@include media-breakpoint-up(xxl) {
			.tile {
				@include responsive-div(140%);
				height: auto;
			}
		}
	}
	.tile {
		@media (min-width: 840px) {
			height: 360px;
		}
		@include media-breakpoint-up(lg) {
			height: 460px;
		}
	}
	.tile__body {
		@include media-breakpoint-up(lg) {
			padding-bottom: 4rem;
			padding-left: 4rem;
			padding-right: 4rem;
		}
	}
}

// Different versions
.v_3-column-tiles--01 {
	.tile-container {
		@media (min-width: 840px) {
			&:first-child {
				padding-top: 9rem;
			}
			&:nth-child(2) {
				padding-top: 22rem;
			}
		}
	}
}

.v_3-column-tiles--02 {
	.tile-container {
		@media (min-width: 840px) {
			&:first-child {
				padding-top: 5rem;
			}
			&:nth-child(2) {
				padding-top: 0;
			}
			&:nth-child(3) {
				padding-top: 15rem;
			}
		}
	}
}

.v_3-column-tiles--03 {
	.tile-container {
		@media (min-width: 840px) {
			&:first-child {
				padding-top: 22rem;
			}
			&:nth-child(2) {
				padding-top: 18rem;
			}
		}
	}
}