@import 'content-block-helpers';
@import 'rich-text-editor';
@import 'hero';
@import 'video';
@import 'image';
@import 'two-columns';
@import 'slider';


@import 'experience'; // Hero and experience been combined.
@import 'booking-bar';
@import 'v_50-50';
@import 'v_4-column-tiles'; // Dependent on comonent _tiles
@import 'v_3-column-tiles'; // Dependent on comonent _tiles
@import 'reviews'; // Dependent on component _tiles
@import 'packages'; // Dependent on comonent _tiles
@import 'webcams';
@import 'map';

// Video bg
// Copied from jquery background video
.bg-video-container {
	position: absolute;
	top: 0;
	left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    animation-duration: 0.6s;
    animation-delay: 0.2s;
    animation-name: fadeIn;
    animation-fill-mode: forwards;
}

.bg-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
    transform: translate(-50%, -50%);
}


.hero-container {
    &:first-child {
        .swiper-slide {
            @include nav-gradient-top();
        }
    }
}

// mailchimp forms
body #mc_embed_signup {
    border-radius: $border-radius;
    margin-left: -15px;
    margin-right: -15px;
    @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
    }
    form {
        padding: 2.4rem 2rem;
        @include media-breakpoint-up(sm) {
            padding: 3rem 3.2rem;
        }
        @include media-breakpoint-up(md) {
            padding: 4rem 4.8rem
        }
    }
    .indicates-required {
        display: none;
    }
    h2 {
        @extend .title;
        font-weight: 400;
        padding-bottom: 1rem;
        margin-top: 0;
        @include media-breakpoint-down(sm) {
            font-size: 2.8rem;
        }
    }

    font: inherit;
    div.mce_inline_error {
        background-color: transparent;
    }
    .mc-field-group {
        width: 100%;
        input {
            padding: 13px 0 13px 0;
            text-indent: 15px;
            border-radius: $border-radius;
            border: 1px solid $form-control-border;
            font-size: 1.4rem;
            font-weight: 400;
            &.mce_inline_error {
                border: 1px solid $error-colour !important;
                border-width: 1px;
            }
            &:focus {
                border: 1px solid #2785C6;
                outline: 0;
            } 
        }
        input[type="checkbox"] {
            position: absolute;
            left: 0;
            z-index: -1;
            width: 1rem;
            height: 1.25rem;
            opacity: 0;
            & + label {
                margin-top: -5px;
                position: relative;
                margin-bottom: 0;
                display: block;
                padding-left: 3.4rem;
                font-weight: 400;
                font-size: 1.4rem;
                padding-top: .5rem;
                padding-bottom: .6rem;
                cursor: pointer;
                &:before {
                    position: absolute;
                    top: 2px;
                    left: 0;
                    display: block;
                    width: 2.4rem;
                    height: 2.4rem;
                    pointer-events: none;
                    content: "";
                    background-color: #fff;
                    border: 1px solid $form-control-border;
                    border-radius: $border-radius;
                    transition: background-color .3s linear;
                }
                &:after {
                    position: absolute;
                    top: 9px;
                    left: 7px;
                    display: block;
                    content: "";
                    background: no-repeat;
                    width: 1.2rem;
                    height: .6rem;
                    border-left: 2px solid white;
                    border-bottom: 2px solid white;
                    transform: rotate(-45deg);
                    opacity: 0;
                    transition: opacity .3s linear;
                }
            }
            &:checked {
                ~ label {
                    &:after {
                        opacity: 1;
                        // background-color: $primary;
                    }
                    &:before {
                        background-color: $primary;
                        border: 1px solid $primary;
                    }
                }
            }
        }
        &.input-group {
            strong {
                @extend .form__label;
                // font-weight: 200;
            }
        }
    }
    .datefield .monthfield input,
    .datefield .dayfield input {
        width: 62px;
        text-indent: 0;
    }
    label {
        @extend .form__label;
    }
    .button {
        padding: .5rem 2.4rem;
        margin-top: 1.6rem;
        height: auto;
        background-color: $primary;
        font-weight: 400;
        font-size: 1.4rem;
        letter-spacing: .2em;
        &:hover,
        &:focus {
            background-color: darken($primary, 5%);
        }
    }
}