$content-block-padding          : 40px;
$content-block-padding-md       : 56px;
$content-block-padding-lg       : 80px;

@mixin content-block-padding() {
    padding-top: $content-block-padding;
    padding-bottom: $content-block-padding;
    @include media-breakpoint-up(md) {
        padding-top: $content-block-padding-md;
        padding-bottom: $content-block-padding-md;
    }
    @include media-breakpoint-up(xl) {
        padding-top: $content-block-padding-lg;
        padding-bottom: $content-block-padding-lg;
    }
}


body:not(.alternative-navigation) {
    .content-block {
        &:first-child {
            padding-top: 0 !important;
        }
    }
} 

.content-block {
    @include content-block-padding();
    position: relative;
    z-index: 1;
    &.no-padding--top {
        padding-top: 0;
    }
    &.no-padding--bottom {
        padding-bottom: 0;
    }
    &.fifty-fifty-container {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.content-block__bg {
    position: absolute;
    top: -2px;
    left: -1px;
    bottom: 0;
    right: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);

    &-item {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        transform: translate(-50%, -50%);
    }

    img {
        width: auto;
        max-width: none;
    }
}