.map-container {
	position: relative;
	height: 90vh;
}
.google-map-ui {
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
	bottom: 0;
	background-color: #cec6c6;
}

.custom-marker {
	width: 248px !important;
	height: 54px !important;
}

.map__pin {
	display: flex;
	align-items: center;
	img {
		width: 75px;
		margin-bottom: -4.2rem;
	}
	&__tag {
		font-size: 1rem;
		font-weight: 800;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		line-height: 1;
		margin-bottom: .4rem;
	}
	&__text {
		margin-bottom: 0;
	}
}

.leaflet-interactive {
    cursor: default !important;
}