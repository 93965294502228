@mixin btn-icon() {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 0;
	background-color: transparent;
	&:hover,
	&:focus {
		outline: 0;
	}
}