// DOTO : move to content blocks, But is it needed.
.hero-container {
	position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 1px;
}


body {
  position: relative;
}

.row-center {
  justify-content: center;
}

.main {
  position: relative;
  overflow: hidden;
  flex: 1;
  z-index: 10;
  min-height: 60vh;
}

.container {
  padding-left: $site-padding-sm;
  padding-right: $site-padding-sm;
  @include media-breakpoint-up(md) {
    padding-left: $site-padding-md;
    padding-right: $site-padding-md;
  }
  @include media-breakpoint-up(lg) {
    padding-left: $site-padding-lg;
    padding-right: $site-padding-lg;
  }
}

.l_row-between {
  justify-content: space-between;
}

.l_order-md-2 {
  @include media-breakpoint-up(md) {
    order: 2;
  }
}

.l_order-md-1 {
  @include media-breakpoint-up(md) {
    order: 1;
  }
}

// footer drawer
.l-content-container {
  position: relative;
  z-index: 1;
  .l-content {
    position: relative;
    overflow: hidden;
    background-color: $body-bg;
    min-height: 100vh;
  }
}
.l-content-spacer {
  padding-bottom: 522px;
  pointer-events: none;
}
body .site-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 0;
}

// Image style added for SEO 
.image--bg-image {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100% !important;
	height: 100%;
	object-fit: cover;
  object-position: center center;
  &.position-top {
		object-position: top center;
	}
}
  
.content-block {
	position: relative;
}

html:not(.ie11) {
  .tile-ie-bg {
    background-image: none;
  }
}
.ie11 .tile-ie-bg {
  background-size: cover;
  background-repeat: no-repeat;
  img {
    display: none;
  }
}

.content-reveal,
.content-reveal-rte {
  visibility: hidden;
}