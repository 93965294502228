[class^="icon-"], [class*=" icon-"] {
    display: inline-block;
    flex-shrink: 0;
    width: $icon-size;
    height: $icon-size;
    vertical-align: middle;
    fill: currentColor;
}
// Added u_ namespacing
.u_bg-image {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.u_text-center {
	text-align: center;
}

.u_text-hide {
	@include hide-text();
}

.u_btn-icon {
	@include btn-icon();
}

.u_reset-btn {
	border: 0;
	background-color: transparent;
	outline: 0;
}

.u_space-between {
	justify-content: space-between;
}

.u_center {
	justify-content: center;
}
.u_end {
	justify-content: flex-end;
}

.u_theme-colour {
	color: $primary;
}

.u_no-break {
	white-space: nowrap;
}

.u_show-overflow {
	overflow: visible !important;
}

[v-cloak] {
	opacity: 0 !important;
	visibility: hidden !important;
}