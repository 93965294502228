.rich-text-editor {
	.rte {
		@include media-breakpoint-up(md) {
			margin-left: $grid-gutter-width * -1;
			margin-right: $grid-gutter-width * -1;
		}
	}
	&.u_align-center {
		.row {
			justify-content: center;
		}
		.rich-text-editor__col {
			margin-left: 0;
		}
		.rte {
			text-align: center;
		}
		ul li {
			&:before {
				display: none;
			}
		}
		ol li {
			list-style-position: inside;
		}
	}
}
.rte {
	p:not(.lead),
	h1,
	h2,
	h3,
	h4,
	h5 {
		&:last-child {
			margin-bottom: 0;
		}
	}
	ul:last-child,
	ol:last-child {
		li {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	h1,
	.h1 {
	  @include font-display-1();
	  &.bold {
		@include font-bold();
	  }
	}
  
	h2,
	.h2 {
	  @include font-display-2();
	  margin-bottom: 4rem;
	  &.bold {
		@include font-regular();
	  }
	}
  
	h3,
	.h3 {
	  @include font-display-3();
	}
  
	h4,
	.h4 {
	  @include font-display-4();
	}
  
	h5,
	.h5 {
	  @include font-display-5();
	}
	p + .button--link,
	ul + .button--link,
	ol + .button--link {
		margin-top: 3rem;
	}
	ul {
		list-style-type: none;
		padding-left: 0;
		li {
			@include font-copy();
			margin-bottom: .6rem;
			position: relative;
			padding-left: 2.2rem;
			&:last-child {
				margin-bottom: 1.8rem;
			}
			&:before {
				position: absolute;
				left: 0;
				top: 0;
				content: "\2014";
			}
		}
	}
	ol {
		padding-left: 1.5rem;
		li {
			@include font-copy();
			margin-bottom: .6rem;
		}
	}
}
.v_50-50 {
	.rte {
		p {
			line-height: 1.928571428571429;
		}
	}
}