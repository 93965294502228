.packages__carousel {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-bottom: 6rem;
    @include media-breakpoint-up(sm) {
        padding-left: 15vw;
        padding-right: 15vw;
    }
    overflow: visible;
    @include media-breakpoint-up(md) {
        padding-left: 6.5rem;
        padding-right: 6.5rem;
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        @include media-breakpoint-up(lg) {
            padding-bottom: 0;
        }
    }
    .swiper-slide {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: flex-start;
        align-items: center;
        padding-left: .5rem;
        padding-right: .5rem;
        position: relative;
        width: 100%;
        min-height: 1px;
        height: auto;
        padding-bottom: 3.6rem;
        @include media-breakpoint-up(md) {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
        }
        @include media-breakpoint-up(lg) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 3.6rem;
        }
        @media (max-width: 768px) {
            &:not(.swiper-slide-active) {
                .card {
                    box-shadow: $hover-drop-shadow;
                }
            }
        }
    }
}

.swiper-navigation--custom {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    @include media-breakpoint-up(lg) {
        display: block;
        position: static;
        bottom: auto;
        left: auto;
        right: auto;
    }
    
    .swiper-button {
        position: relative;
        color: $body-colour;
        top: calc(50% + 5rem);
        margin-left: 4px;
        margin-right: 4px;
        left: auto;
        right: auto;
        @include media-breakpoint-up(lg) {
            position: absolute;
            margin-left: 0;
            margin-right: 0;
        }
    }
    .swiper-button-prev {
        @include media-breakpoint-up(lg) {
            left: -7.2rem;
        }
        
    }
    .swiper-button-next {
        @include media-breakpoint-up(lg) {
            right: -7.2rem;
        }
    }
}

.packages-container {
    padding-top: 10rem;
    position: relative;
    @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
    }
}

.card--package {
    text-decoration: none;
    min-height: 1px;
    box-shadow: $drop-shadow-null;
    transition: all .3s ease;
    .card__image {
        min-height: 1px;
        img {
            width: 100%;
            max-width: 100%;
        }
    }
    .card__title {
        font-weight: 300;
        line-height: 2.4rem;
        font-size: 1.6rem;
        @include media-breakpoint-up(sm) {
            font-size: 2rem;
        }
    }
    .card__body {
        padding: 2rem 2rem 0 2rem;
        @include media-breakpoint-up(lg) {
            padding: 2rem 4rem 0 4rem;
        }
    }
    .card__footer {
        padding: 0 2rem 2rem 2rem;
        justify-content: flex-start;
        @include media-breakpoint-up(lg) {
            padding: 0 4rem 2rem 4rem;
        }
    }
    .card__price {
        display: block;
        margin-bottom: 3.4rem;
    }
}
body:not(.mobile) {
    .card--package,
    .tile {
        box-shadow: $drop-shadow-null;
        transition: all .3s ease;
        &:hover {
            box-shadow: $hover-drop-shadow;
        }
    }
}