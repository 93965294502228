// Grid overlay For development only
$showGrid                       : tru;
$bodyRuler                      : tru;
$grid-bg                        : rgba(255, 0, 0, .1);
/////////////////////////////

// SIZES
$root-font-size 			    : 10px;
$base-font-size 			    : 1.8rem;
$large-screen-gutter            : .55vw;

$icon-size                      : 2.4rem;

// Content-block padding
$cb-padding-sm 				    : 3.2rem;
$cb-padding-md 				    : 5.6rem;
$cb-padding-lg 				    : 7.2rem;

// padding left / right eg. header spacing.
$site-padding-sm                : 3rem;
$site-padding-md                : 3.6rem;
$site-padding-lg                : 4rem;

$site-width                     : 1240px;

$border-radius                  : 5px;

// Colours 

$primary                        : #FF7747;
$secondary                      : #001532;
$tertiary                       : #2785C6;
$animate-line-colour            : #42B2FF;

$h-blue                         : #094975;

$light-white-text               : rgba(255, 255, 255, .9); 

$error-colour                   : #DA083A;


// DOTO : check these colours
$j-grey                         : #F2EEE4;
$j-bg-grey                      : #FBF8F2;
$j-black                        : #262626;
$j-green                        : #929A78;

$j-white                        : #ffffff;
$white                          : #ffffff;
$black                          : #ffffff;

$body-colour                    : #001532;
$body-bg                        : #F5F7FA;

// Amimation
$thibault-swing                 : cubic-bezier(.75,0,.25,1);

// gradient
$gradient-colour-start          : $secondary;
$gradient-colour-end            : #003255;
$gradient                       : linear-gradient(135deg, #{$gradient-colour-start} 0%,#{$gradient-colour-end} 100%);

$gallery-gradient               : linear-gradient(to right,  rgba(0,21,50,0.28) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,0) 80%,rgba(0,21,50,0.28) 100%);

// gradient over image

$gradient-overlay-colour-start  : rgba(0,21,50,0.9);
$gradient-overlay-colour-end    : rgba(0,21,50,0);
$gradient-overlay-left          : linear-gradient(to right,  $gradient-overlay-colour-start 0%,$gradient-overlay-colour-end 100%);
$gradient-overlay-right         : linear-gradient(to left,  $gradient-overlay-colour-start 0%,$gradient-overlay-colour-end 100%);

// Site Header 
$site-header-height-sm          : 64px;
$site-header-height-lg          : 75px;
$site-header-bg                 : white;

// Site Footer
$site-footer-bg                 : $secondary;

// Z-index
$site-header-index              : 100;

// Data Icons
$caret                          : "data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.939453 1L4.92427 5L0.939453 9' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e";

$arrow                          : "data:image/svg+xml;charset=UTF-8,%3csvg width='22' height='10' viewBox='0 0 22 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16.9395 1L20.9243 5L16.9395 9' stroke='%23FF7747' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M1 5L19.9279 5' stroke='%23FF7747' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e";

$arrow-light                    : "data:image/svg+xml;charset=UTF-8,%3csvg width='22' height='10' viewBox='0 0 22 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16.9395 1L20.9243 5L16.9395 9' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M1 5L19.9279 5' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e";

$dropdown-arrow-light           : "data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10.9998 1L5.88864 6L0.999756 1' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e";

$dropdown-arrow-dark            : "data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10.9998 1L5.88864 6L0.999756 1' stroke='%231E1E1E' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e";


$hover-drop-shadow              : 0px 21px 19px 0px rgba(0,0,0,0.06);

$drop-shadow-null               : 0px 21px 19px 0px rgba(0,0,0,0.0);