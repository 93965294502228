// FONTS
$fs-extralight: 'Muli', sans-serif;
$fs-light: 'Muli', sans-serif;
$fs-regular: 'Muli', sans-serif;
$fs-bold: 'Muli', sans-serif;
$fs-extrabold: 'Muli', sans-serif;

@mixin font-extralight() {
	font-family: $fs-extralight;
	font-weight: 200;
}

@mixin font-light() {
	font-family: $fs-light;
	font-weight: 300;
}

@mixin font-regular() {
	font-family: $fs-regular;
	font-weight: 400;
}

@mixin font-bold() {
	font-family: $fs-bold;
	font-weight: 600;
}

@mixin font-extrabold() {
	font-family: $fs-extrabold;
	font-weight: 800;
}

@mixin font-btn() {
    @include font-bold();
    text-transform: uppercase;
}