@mixin banner-height {
	display: flex;
	align-items: center;
	min-height: 466px;
	@include media-breakpoint-up(sm) {
		height: 540px;
		min-height: 540px;
	}
	@include media-breakpoint-up(md) {
		height: 580px;
		min-height: 580px;
	}
	@include media-breakpoint-up(xl) {
		height: 80vh;
		min-height: 580px;
		max-height: 740px;
	}
}