@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}



@mixin font-display-1() {
  @include font-extralight();
  font-size: 2.8rem;
  line-height: 1.464285714285714;
  @include media-breakpoint-up(md) {
    font-size: 3.2rem;
  }
  @include media-breakpoint-up(lg) {
    // font-size: 3.22581vw;
    // font-size: 4.8rem;
    line-height: 1.395833333333333;
    // background-color: red;
    @include fluid-type(980px, 1200px, 32px, 38px);
  }
  @include media-breakpoint-up(xl) {
    // background-color: blue;
    @include fluid-type(1200px, 1720px, 38px, 48px);
  }
}

@mixin font-display-2() {
  @include font-extralight();
  font-size: 2.8rem;
  // line-height: 1.527777777777778;
  line-height: 1.25;
  @include media-breakpoint-up(md) {
    font-size: 2.8rem;
  }
  @include media-breakpoint-up(lg) {
    @include fluid-type(980px, 1200px, 24px, 28px);
  }
  @include media-breakpoint-up(xl) {
    @include fluid-type(1200px, 1720px, 28px, 34px);
  }
}

@mixin font-display-3() {
  @include font-extralight();
  font-size: 2.4rem;
  line-height: 1.25;
  @include media-breakpoint-up(md) {
    font-size: 2.4rem;
  }
  @include media-breakpoint-up(lg) {
    @include fluid-type(980px, 1200px, 20px, 24px);
  }
  @include media-breakpoint-up(xl) {
    @include fluid-type(1200px, 1720px, 24px, 30px);
  }
}

@mixin font-display-4() {
  @include font-regular();
  font-size: 1.4rem;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
}

@mixin font-display-5() {
  @include font-light();
  font-size: 1.8rem;
  line-height: 1.111111111111111;
  text-transform: uppercase;
  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
  }
}

@mixin font-copy() {
  @include font-light();
  font-size: 1.4rem;
  line-height: 1.5;
  strong {
    @include font-bold();
  }
}

@mixin font-quote() {
  @include font-regular();
  font-size: 1.6rem;
  line-height: 1.625;
  font-style: italic;
}

@mixin font-button() {
  @include font-regular();
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: .2em;
  text-transform: uppercase;
}

@mixin font-small() {
  @include font-light();
  font-size: 1.2rem;
  line-height: 1.5;
}

@mixin font-label() {
  @include font-extralight();
  font-size: 2rem;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

// Other styles
.quote {
  @include font-quote();
}

p {
  @include font-copy();
  margin-bottom: 1.8rem;
}

.h4 {
  @include font-display-4();
}

.title {
  @include font-display-2();
}

.content-block__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.content-block__title {
  @extend .title;
  font-weight: 400;
  padding-right: 1.4rem;
  margin-bottom: 0;
  margin-bottom: 2px;
  @include media-breakpoint-down(sm) {
    font-size: 2.8rem;
  }
}

.content-block__subtitle {
  @include font-display-4();
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: .8rem;
  @include media-breakpoint-down(sm) {
    font-size: 1.2rem;
  }
}

.subtitle__icon {
  margin-left: -.6rem;
  padding-top: .4rem;
}
.subtitle__text {
  display: block;
  flex: 0 0 278px;
  max-width: 278px;
  padding-left: 1.6rem;
  line-height: 23px;
}