.hero-container {
	position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 1px;
}

.hero {
    position: relative;
    overflow: hidden;
	@include banner-height();
    color: #fff;

    .hero__row {
        justify-content: center;
    }
    .hero__content {
        text-align: center;
    }
    
    &.is-align-top {
        align-items: flex-start;
    }
    &.is-align-bottom {
        align-items: flex-end;
    }

    &.is-align-left {
        .hero__row {
            justify-content: flex-start;
        }
        .hero__content {
            text-align: left;
        }
    }
    &.is-align-right {
        .hero__row {
            justify-content: flex-end;
        }
        .hero__content {
            text-align: right;
        }
    }
}