$button-size    : 5.2rem;

.button {
  @include font-button();
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 0;
  outline: 0;
  padding: 0;
  padding-top: .8rem;
  padding-bottom: .8rem;
  color: $primary;
  background-color: transparent;
  overflow: visible;
  cursor: pointer;
  &:active,
  &:hover {
    outline: 0;
  }
}

.button__text {
  position: relative;
  z-index: 1;
}

.button--button {
  position: relative;
  height: $button-size;
  padding-right: $button-size + 2.5rem;
  padding-left: 5.2rem;
  transition: all .25s ease;
  &:hover,
  &:focus {
    color: white;
    &:before {
      width: 100%;
      transition: all .3s $thibault-swing;
    }
  }
  &:before {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: $button-size;
    border-radius: $border-radius 0 $border-radius 0;
    content: '';
    background-color: $primary;
    background-repeat: no-repeat;
    background-position: center right 2.2rem;
    transition: width .3s cubic-bezier(.75,0,.25,1);
    background-image: url("#{$caret}");
  }
}
.button--button:disabled:not(.is-loading),
.button--button[disabled]:not(.is-loading) {
  opacity: .5;
  pointer-events: none;
  .button__text {
    visibility: hidden;
  }
}

.button--link {
  // transition: font-weight .25s linear;
  &:hover,
  &:focus {
    @include font-extrabold();
    letter-spacing: 0.175em;
    &:after {
      transform: translateX(2rem);
    }
  }
  &:after {
    content: '';
    width: 2.2rem;
    height: 1.2rem;
    background-image: url("#{$arrow}");
    background-position: center center;
    background-repeat: no-repeat;
    transform: translateX(1rem);
    transition: transform .25s ease-in-out;
  }
}

.button--link-light {
	@extend .button--link;
  color: $white;
  &:after {
    background-image: url("#{$arrow-light}");
  }
}


.button--play {
  color: $white;
  .button__icon {
    display: inline-block;
    width: 4.8rem;
    height: 4.8rem;
    margin-right: 1.6rem;
    position: relative;
    // svg {
      // width: 100%;
      // height: 100%;
      // position: absolute;
      // top: 0;
      // left: 0;
      // transition: all .3s $thibault-swing;
	    // will-change: transform;
      // &:before {
      //   content: '';
      //   display: block;
      //   width: 0;
      //   height: 0;
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   border-radius: 50%;
      //   transform: translate(-50%, -50%);
      //   transition: all .3s $thibault-swing;
      //   background-color: transparent;
      // }
    
  }
  &.is-full {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

body:not(.mobile) {
  .button--play {
    &:hover {
      .button__text {
        font-weight: 800;
        letter-spacing: 0.175em;
      }
      // svg {
      //   transform: scale(1.15, 1.15);
      //   &:before {
      //     width: 26px;
      //     height: 26px;
      //     background-color: #ffffff26;
      //   }
      // }
    }
  }
}

.button--secondary {
  color: white;
  background-color: $secondary;
  width: 100%;
  height: 52px;
  border-radius: $border-radius;
}