.experience {
	display: flex;
	overflow: hidden;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	

	&.u_align-center {
		.row {
			justify-content: center;
		}
		.content-block__content {
			margin-left: 0;
		}
		.rte {
			text-align: center;
		}
		.col-16 {
			margin-left: 0;
		}
		.content-block__content-container {
			&:before {
				@include media-breakpoint-up(md) {
					left: 0;
					right: 0;
					width: 100%;
					background: rgba(0, 21, 50, .3);
				}
			}
		}
		.swiper-container-initialized .swiper-navigation {
			margin: 0 auto;
		}
    }

	&.u_align-right {
		.row {
			justify-content: flex-end;
		}
		.content-block__content {
			margin-left: 0;
		}
		.content-block__content-container {
			&:before {
				@include media-breakpoint-up(md) {
					background: $gradient-overlay-right;
					left: auto;
					right: 0;
				}
			}
		}
    }
    .content-block__height {
        min-height: 90vh;
        flex: 0 0 0;
        max-width: 0;
    }
	.content-block__content {
		@include media-breakpoint-up(md) {
			margin-left: $grid-gutter-width * -1;
		}
    }
    .content-block__content-container {
        position: relative;
        z-index: 2;
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        padding-top: 8rem;
		padding-bottom: 8rem;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			background: $gradient-overlay-left;
			width: 100%;
			pointer-events: none;
			@include media-breakpoint-up(md) {
				width: 75%;
			}
		}
    }
}

.experience__logo {
    padding-bottom: 2.4rem;
	img {
		max-width: 219px;
	}
}

.hero__carousel {
	position: relative;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	.content-block__content-container {
		position: relative;
		z-index: 2;
		height: 100%;
	}
	.swiper-wrapper {
		position: absolute;
		top: 0;
		left: 0;
	}
}



$swiper-button-icon-size 		: 3.4rem;

.experience {
	.rte {
		h1,
		.h1,
		h2,
		.h2 {
			margin-bottom: .6rem;
		}
		h1,
		.h1 {
			line-height: 1.2;
		}
		.cta {
			margin-top: 5rem;
		}
	}
	.swiper-navigation {
		width: 120px;
		justify-content: space-between;
		margin-left: -4px;
		padding-bottom: 2.8rem;
		display: none;
	}
	.swiper-container-initialized {
		.swiper-navigation {
			display: flex;
		}
	}
	.swiper-button {
		color: white;
		background-color: transparent;
		border: 0;
		outline: 0;
		position: relative;
		width: calc(#{$swiper-button-icon-size} + 8px);
		height: calc(#{$swiper-button-icon-size} + 8px);;
		padding: 4px;
		transform: none;
		display: block;
		margin-top: 0;
		left: auto;
		right: auto;
		svg {
			width: $swiper-button-icon-size;
			height: $swiper-button-icon-size;
		}
	}
	.cta-video {
		padding-top: 3rem;
		padding-bottom: 3rem;
		& + .cta {
			margin-top: 1rem;
		}
	}
}