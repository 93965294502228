// Country flags
.country {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	width: 7.6rem;
	width: 10.4rem;
	@media (max-width: 767px) {
		width: 100%;
		flex: 0 0 100%;
		max-width: 100%;
		.country__selected {
			display: none;
		}
		.country__list {
			opacity: 1;
			pointer-events: auto;
			width: 100%;
			border: 0 !important;
			position: relative;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: transparent !important;
			.country__link {
				padding: .7rem 1.8rem;
				&.active {
					display: block;
					img {
						opacity: 1;
					}
				}
				img {
					opacity: .5;
					margin-right: 0;
				}
			}
		}
		
		.country__link-text {
			display: none;
		}
	}
}

.country__list {
	position: absolute;
    top: calc(100% + 14px);
	left: 0;
    width: 7.6rem;
    padding: 0;
	margin: 0;
    list-style-type: none;
    opacity: 0;
    pointer-events: none;
    padding-top: .4rem;
    padding-bottom: .4rem;
	transition: all .3s ease;
	border-radius: $border-radius;
	border: 1px solid rgba(255, 255, 255, .2);
	.is-active & {
		opacity: 1;
		pointer-events: auto;
	}
	.has-bg & {
		border: 1px solid #EBEBEB;
		background-color: $white;
	}
	.country__link {
		@include font-bold();
		padding: .7rem 1rem;
		display: flex;
		align-items: center;
		position: relative;
		transition: all .35s ease;
		text-decoration: none !important;
		height: 100%;
		color: $white;
		font-size: 1.3rem;
		.has-bg & {
			color: $body-colour;
		}
		img {
			margin-right: 9px;
		}
		&.active {
			display: none;
		}
	}
}

.country__selected {
	height: 100%;
    padding-left: 1.1rem;
	padding-right: .8rem;
	display: flex;
	.country__link {
		height: 100%;
		display: inline-flex;
		align-items: center;
		text-decoration: none !important;
		height: 100%;
		&.active {
			display: none;
		}
	}
}
.country__link,
.country__selected {
	img {
		height: 16px;
		display: block;
	}
}

.country__list-icon {
	display: block;
	width: 12px;
	height: 7px;
	margin-left: 1rem;
	background-image: url("#{$dropdown-arrow-light}");
	background-repeat: no-repeat;
	background-position: center center;
	.has-bg & {
		background-image: url("#{$dropdown-arrow-dark}");
	}
}