$clndr-mute-text            : rgba(0, 0, 0, .12);
$clndr-selected             : rgba(255, 119, 71, .25);

.noselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.mini-clndr {
    position: absolute;
    bottom: calc(100% - 3px);
    left: 0;
    margin: 0 auto;
    width: 200%;
    @include media-breakpoint-up(sm) {
        width: 100%;
        border-bottom: 3px solid transparent;
    }
    max-height: 0;
    overflow: hidden;
    
    transition: all .3s $thibault-swing;

    &.is-shown {
        max-height: 300px;
        z-index: 1;
        @include media-breakpoint-up(sm) {
            border-bottom: 3px solid white;
        }
        &:before {
            opacity: 1;
        }
    }
    &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        content: '';
        display: block;
        height: 1px;
        background-color: rgba(210, 213, 218, .75);
        opacity: 0;
        transition: opacity .3s $thibault-swing;
    }
}
.clndr-container {
    cursor: pointer;
    * {
		cursor: pointer;
	}
}
.booking-bar__item {
    & + .booking-bar__item {
        .mini-clndr {
            left: calc((100% + 2px) * -1);
            width: calc(200% + 2px);
            @include media-breakpoint-up(sm) {
                left: 0;
                width: 100%;
            }
        }
    }
}

.clndr {
    overflow: hidden;
    box-shadow: 0px -1px 3px 0px rgba(0,0,0,0.10);
    border-radius: $border-radius $border-radius 0 0;
}

.clndr .controls {
    border-radius: $border-radius $border-radius 0 0;
    background-color: #F5F7FA;
    color: $body-colour;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    padding-left: 3rem;
    padding-right: 3rem;
    @include media-breakpoint-up(sm) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    @media (min-width: 720px) {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    @include media-breakpoint-up(md) {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }
    @media (min-width: 820px) {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

.clndr-previous-button,
.clndr-next-button {
    width: 15%;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    transition: background-color 0.5s;
    svg {
        width: 22px;
        height: 22px;
    }
}

.clndr .month {
    width: 70%;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-weight: 800;
    font-size: 1.4rem;
    line-height: 1;
    @include media-breakpoint-up(sm) {
        font-size: 1.2rem;
    }
    @media (min-width: 720px) {
        font-size: 1.4rem;
    }
}

.clndr .days-container {
    position: relative;
    width: 100%;
    display: block;
}
.clndr {
    .day-item {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
    }
    .days {
        position: relative;
        left: 0;
        width: 100%;
        transition: left 0.5s;
        background-color: white;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-bottom: .6rem;
        @include media-breakpoint-up(sm) {
            padding-left: 1.6rem;
            padding-right: 1.6rem;
        }
        @media (min-width: 720px) {
            padding-left: 3rem;
            padding-right: 3rem;
        }
        @include media-breakpoint-up(md) {
            padding-left: 1.6rem;
            padding-right: 1.6rem;
        }
        @media (min-width: 820px) {
            padding-left: 2.4rem;
            padding-right: 2.4rem;
        }
        @include media-breakpoint-up(lg) {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }
    .day,
    .empty {
        width: 14.28571428571429%;
        height: 3rem;
        display: inline-flex;
        justify-content: center;
        font-size: 12px;
        text-align: center;
        color: $body-colour;
        position: relative;
        &:hover {
            .day-item {
                background-color: $clndr-selected;
            }
        }
        &.event {
            cursor: pointer;
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: '';
                display: block;
                background-color: $clndr-selected;
            }
            &.event--start {
                &:before {
                    left: 50%;
                }
            }
            &.event--end {
                &:before {
                    right: 50%;
                }
            }
            &.event--start,
            &.event--end {
                color: white;
                .day-item {
                    background-color: $primary;
                }
            }
        }
        &.adjacent-month {
            color: $clndr-mute-text;
        }
    }
    .past {
        color: $clndr-mute-text;
        pointer-events: none;
    }
    .empty {
        height: 31px;
        vertical-align: bottom;
    }
    .headers {
        background-color: white;
        padding-top: .8rem;
        padding-bottom: .8rem;
    }
    .day-header {
        width: 14.28571428571429%;
        display: inline-block;
        text-align: center;
        color: $clndr-mute-text;
        text-transform: uppercase;
        font-size: 1.3rem;
        letter-spacing: 0.16em;
        font-weight: 800;
    }
}