// $footer-bg 				: #333;
// $footer-color			: white;

.site-footer {
    position: relative;
    padding-top: 2.7rem;
    padding-bottom: 4.4rem;
    background-color: $site-footer-bg;
    background: $gradient;
    color: white;
    @include media-breakpoint-up(md) {
        padding-top: 4.4rem;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 9.6rem;
        padding-bottom: 8.9rem;
    }
    a {
        color: white;
    }
}


.site-footer__content {
    text-align: center;
    padding-top: 3rem;
    @include media-breakpoint-up(md) {
        padding-top: 0;
        text-align: left;
        padding-right: 5rem;
    }
    p {
        max-width: 306px;
        margin: 0 auto;
        padding-right: 3rem;
        @include media-breakpoint-up(md) {
            margin: 0;
        }
    }
}

.site-footer__qual-mark {
    padding-bottom: 3rem;
    text-align: center;
    @include media-breakpoint-up(md) {
        float: right;
        padding-top: 10.2rem;
        margin-right: -5rem;
    }
    img {
        height: 54px;
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}

.site-footer__BEIA {
    padding-bottom: 3rem;
    text-align: center;
    margin-top: 85px;
    height: 59px;
    @include media-breakpoint-up(md) {
        float: right;
        padding-top: 10.2rem;
        margin-right: -5.2rem;
    }
    img {
        height: 54px;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}

.site-footer__logo {
    padding-bottom: 3.2rem;
}

.site-footer__bottom {
    padding-top: 3.4rem;
    @include media-breakpoint-up(md) {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding-top: 6.4rem;
    }
}

// Footer info
.site-footer__info {
    @include font-regular();
    color: rgba(255, 255, 255, .75);
    font-size: 1.2rem;
    text-align: center;
    @include media-breakpoint-up(md) {
        text-align: left;
    }
    p {
        margin-bottom: 0;
        line-height: 1.5;
        a {
            color: inherit;
            text-decoration: none;
        }
    }
}

// Footer navigation
.site-footer__navigation {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: flex-start;
        padding-top: 8.8rem;
    }
    .country {
        padding-bottom: 1.8rem;
        @include media-breakpoint-up(md) {
            padding-top: 1rem;
            padding-bottom: 0;
            width: 7.6rem;
        }
        @include media-breakpoint-up(lg) {
            width: 10.2rem;
        }
    }
    
 .navigation {
    padding-left: 0;
    margin: 0;
    list-style-type: none;
    flex: 1;
    column-count: 2;
    column-gap: 3rem;
    -webkit-perspective:1; // Chrome is reported to incorrectly calculate the container height, and often breaks on margins, padding, and can display 1px of the next column at the bottom of the previous column
    @include media-breakpoint-up(lg) {
        column-count: 3;
    }
    li {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }
 }   
 .navigation__link {
    @include font-regular();
    font-size: 1.4rem;
    text-decoration: none;
    display: block;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    text-align: center;
    @include media-breakpoint-up(md) {
        text-align: left;
    }
 }
}

.site-footer__build-by {
    display: inline-block;
    overflow: hidden;
    a {
      span { display: inline !important; }
    }
}