// CORE
@import "core/core";


@mixin nav-gradient-top() {
	position: relative;
	&:before {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        top: 0;
		left: 0;
		right: 0;
        height: $site-header-height-sm;
        height: 218px;
        background: linear-gradient(to bottom,  rgba(1,31,60,1) 0%,rgba(1,31,60,0) 100%);
        pointer-events: none;
        z-index: 2;
        @include media-breakpoint-up(lg) {
            height: $site-header-height-lg;
            height: 218px;
        }
    }
}


@mixin card-shadow() {
	box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.04);
}

@mixin nav-hover() {
	span {
		position: relative;
		overflow: hidden;
		display: inline-block;
		padding-bottom: 2px;
		padding-top: 2px;
		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			height: 1px;
			width: 0;
			background-color: currentColor;
			transition: width .3s cubic-bezier(.75,0,.25,1);
		}
	}
	html:not(.mobile) & {
		&:hover,
		&:focus {
			span {
				&:before {
					transition: width .3s cubic-bezier(.75,0,.25,1);
					width: 100%;
				}
			}
		}
	}
}

.navigation__link,
.site-footer__build-by a {
	@include nav-hover();
}

@mixin spinner($size) {
	position: relative;
	display: inline-block;
	width: $size;
	height: $size;
	&:before {
		content: '';
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		left: 50%;
		width: $size;
		height: $size;
		margin-top: ($size / 2) * -1;
		margin-left: ($size / 2) * -1;
		border-radius: 50%;
		border: 2px solid transparent;
		border-top-color: currentColor;
		border-bottom-color: currentColor;
		animation: spinner .8s ease infinite;
	}
}

// @import "bootstrap/bootstrap";
@import "basic-bootstrap/bootstrap"; // Light version of bootstrap Grid, Mixin and Functions

// PLUGINS
@import "plugins/plugins";

// Components
@import "components/components";

// PARTIALS
@import "partials/partials";

// Content-blocks
@import "content-blocks/content-blocks";

.gradient {
	background: $gradient;
}

html {
	scroll-behavior: smooth;
}

body {
	color: $body-colour;
	background-color: $body-bg;
}

// $font-family-base:            $font-family-sans-serif !default;

// Default content block padding
@mixin cb-padding-default {
	padding-top: $cb-padding-sm * 2;
	padding-bottom: $cb-padding-sm * 2;
	@include media-breakpoint-up(md) {
		padding-top: $cb-padding-md * 2;
		padding-bottom: $cb-padding-md * 2;
	}
	@include media-breakpoint-up(xl) {
		padding-top: $cb-padding-lg * 2;
		padding-bottom: $cb-padding-lg * 2;
	}
}

// Top small
@mixin cb-padding-top-sm {
    padding-top: $cb-padding-sm;
	@include media-breakpoint-up(md) {
		padding-top: $cb-padding-md;
	}
	@include media-breakpoint-up(xl) {
		padding-top: $cb-padding-lg;
	}
}

// top large
@mixin cb-padding-top-lg {
    padding-top: $cb-padding-sm * 4;
    @include media-breakpoint-up(md) {
		padding-top: $cb-padding-md * 4;
	}
	@include media-breakpoint-up(xl) {
		padding-top: $cb-padding-lg * 4;
	}
}

// Bottom small
@mixin cb-padding-bottom-sm {
    padding-bottom: $cb-padding-sm ;
    @include media-breakpoint-up(md) {
		padding-bottom: $cb-padding-md;
	}
	@include media-breakpoint-up(xl) {
		padding-bottom: $cb-padding-lg;
	}
}

// Bottom large
@mixin cb-padding-bottom-lg {
    padding-bottom: $cb-padding-sm * 4;
    @include media-breakpoint-up(md) {
		padding-bottom: $cb-padding-md * 4;
	}
	@include media-breakpoint-up(xl) {
		padding-bottom: $cb-padding-lg * 4;
	}
}

.content-block {
	position: relative;
	@include cb-padding-default();
	overflow: hidden;
}

// Padding top
.u_padding-top-sm {
	@include cb-padding-top-sm();
}

.u_padding-top-lg {
	@include cb-padding-top-lg();
}

// Padding bottom
.u_padding-bottom-lg {
	@include cb-padding-bottom-lg();
}

.u_padding-bottom-sm {
	@include cb-padding-bottom-sm();
}

// Padding None
.u_padding-top-none {
	padding-top: 0 !important;
}
.u_padding-bottom-none {
	padding-bottom: 0 !important;
}

//////////////////////////////////////////
// Grid
.col-item {
	padding: 8px 0;
	color: black;
	background-color: $grid-bg;
	text-align: center;
	margin-bottom: 24px;
}

// Visual grid
.visual-grid {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 15;
	display: none;
	pointer-events: none;
	@if ($showGrid == true) {
		display: block;
	}
	.col-item,
	.row,
	.container {
		height: 100%;
	}
}

body {
	&:before {
		content: "";
		position: fixed;
		background-color: red;
		left: 0;
        top: 106px;
        z-index: 999;
		width: 100%;
		height: 40px;
        display: none;
        @if ($bodyRuler == true) {
            display: block;
        }
	}
}
//////////////////////////////////////////

.theme-light {
	color: white;
}


.i-block {
	display: flex;
	align-items: center;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-top: 1.1rem;
	padding-bottom: 1.1rem;
	padding-right: 1rem;
}
.i-block__icon + .i-block__text {
	margin-left: 2.4rem;
	@include media-breakpoint-up(md) {
		margin-left: 3rem;
	}
}

.i-block--sm {
	.i-block__icon {
		width: 1.8rem;
		height: 1.8rem;
		& + .i-block__text {
			@include font-small();
			font-size: 12.45px;
			line-height: 1.2;
			margin-left: 1.2rem;
		}
	}
}

.i-block__text {
	font-size: 1.6rem;
	line-height: 1.25;
	a {
		text-decoration: none;
		color: currentColor;
	}
}

.i-cb-block {
	.i-block-container {
		padding-top: 1.2rem;
		padding-bottom: 2.2rem;
		margin-left: -15px;
		margin-right: -15px;
		background: $white;
		border-radius: $border-radius;
		display: flex;
		flex-wrap: wrap;
		@include card-shadow();
		@include media-breakpoint-up(lg) {
			margin-left: -30px;
			margin-right: -30px;
		}
		.i-block {
			padding-left: 2rem;
			padding-right: 2rem;
			flex: 0 0 100%;
			max-width: 100%;
			margin-top: .5rem;
			margin-bottom: .5rem;
			@include media-breakpoint-up(sm) {
				flex: 0 0 50%;
				max-width: 50%;
			}
			@include media-breakpoint-up(lg) {
				flex: 0 0 33.333333%;
				max-width: 33.333333%;
				padding-left: 3rem;
				padding-right: 3rem;
			}
		}
		.i-block-line {
			flex: 0 0 100%;
			max-width: 100%;
			height: 1px;
			background-color: rgba(0, 0, 0, .1);
			margin-top: 1.2rem;
			margin-bottom: 1.2rem;
			&.hide {
				height: 0;
				background-color: rgba(0, 0, 0, 0);
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}
}

// Swiper specific
.swiper-button {
	background: transparent;
	border: 0;
	color: white;
}

.swiper-slide {
	overflow: hidden;
	.u_bg-image {
		right: -1px;
		left: -1px;
	}
}
//////////////

.card {
	.card__carousel {
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: $gallery-gradient;
			z-index: 1;
			pointer-events: none;
		}
	}
	// .swiper-slide {
		
	// }
	.swiper-button {
		width: 4.4rem;
		height: 4.4rem;
		padding: 0;
		svg {
			width: 3.4rem;
			height: 3.4rem;
		}
	}
}

.carousel__expand {
	color: white;
	position: absolute;
	top: .2rem;
	right: 0;
	z-index: 2;
	cursor: pointer;
	padding-left: 0;
	padding-right: 0;
	width: 4.4rem;
	height: 4.4rem;
}

// Gallery
.gallery {
	.gallery__carousel {
		.swiper-slide {
			max-height: calc(100vh - 75px);
			min-height: 90vh;
			@include responsive-div(48%);
			@include media-breakpoint-up(lg) {
				min-height: 90vh;
			}
		}
		.swiper-pagination,
		.carousel__expand,
		.swiper-navigation {
			display: none;
		}
		&.swiper-container-initialized {
			.swiper-pagination,
			.swiper-navigation {
				display: block;
			}
			.carousel__expand {
				display: flex;
			}
		}
	}
	.swiper-button {
		width: 46px;
		height: 46px;
		svg {
			width: 34px;
			height: 34px;
		}
	}
	.swiper-button-prev {
		left: 24px;
		@include media-breakpoint-up(lg) {
			left: 34px;
		}
		@include media-breakpoint-up(xxl) {
			left: 84px;
		}
	}
	.swiper-button-next {
		left: auto;
		right: 24px;
		@include media-breakpoint-up(lg) {
			right: 34px;
		}
		@include media-breakpoint-up(xxl) {
			right: 84px;
		}
	}
	.carousel__expand {
		top: 2rem;
		right: 1.8rem;
	}
}
.gallery__caption-container {
	position: absolute;
	bottom: 2.2rem;
	width: 100%;
	z-index: 1;
	@media (min-width: 860px) {
		bottom: 3rem;
	}
}
.gallery__caption {
	@include font-bold();
	padding-left: 3rem;
	padding-right: 3rem;
	margin-bottom: 0;
	color: white;
	font-size: 1.4rem;
	height: 42px;
	overflow: hidden;
	opacity: 0;
	transition: opacity .5s $thibault-swing .5s;
	text-align: center;
	@include media-breakpoint-up(md) {
		padding-left: 3.6rem;
		padding-right: 3.6rem;
	}
	@include media-breakpoint-up(lg) {
		padding-left: 4rem;
	}
	@media (min-width: 860px) {
		padding-right: 0;
		height: auto;
		max-width: 42.5%;
		white-space: nowrap;
		text-overflow: ellipsis;
		text-align: left;
	}
	.swiper-slide-active & {
		opacity: 1;
	}
}

.gallery {
	.swiper-pagination-fraction,
	.swiper-pagination-custom,
	.swiper-container-horizontal > .swiper-pagination-bullets {
		@media (max-width: 859px) {
			bottom: 84px;
		}
	}
	.swiper-slide {
		&:after {
			content: '';
			display: block;
			background: linear-gradient(0deg, rgba(0,5,48,0.27) 0%, rgba(0,3,34,0) 100%);
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 44%;
			z-index: 0;
		}
	}
}

.gallery__title {
	margin-bottom: 4.4rem !important;
	@include media-breakpoint-up(md) {
		margin-bottom: 5.6rem !important;
	}
	@include media-breakpoint-up(lg) {
		margin-bottom: 6.4rem !important;
	}
}

.swiper-pagination-bullet {
	transform: scale(0.85, 0.85);
	background: rgba(255, 255, 255, .5);
	opacity: 1;
	&.swiper-pagination-bullet-active {
		transform: scale(1, 1);
		background: rgba(255, 255, 255, 1);
	}
}

.swiper-button {
	outline: 0 !important;
}

.swiper-button {
	width: 46px;
	height: 46px;
	padding: 0;
	justify-content: center;
	display: inline-flex;
	svg {
		width: 34px;
		height: 34px;
		display: block;
		flex-shrink: 0;
	}
}


// Flockler

.gradientIconTemplate {
	width: 0;
	height: 0;
  }

.sm-flockler__content {
	width: 268px;
	flex: 0 0 268px;
	max-width: 268px;
	padding-right: 4rem;
	padding-top: .6rem;
	@include media-breakpoint-up(lg) {
		margin-left: -3rem;
	}
	h4 {
		@include font-display-2();
		line-height: 1.25;
		margin-bottom: 2.8rem;
	}
}
.sm-flockler__image {
	@include responsive-div(100%);
	transition: transform .4s $thibault-swing;
	transition: transform .4s ease;
	will-change: transform;
	image-rendering: pixelated;
}
.sm-flockler__carousel {
	overflow: visible;
	flex: 1;
	margin-left: -.3rem;
	margin-right: -.3rem;
	.swiper-slide {
		width: 50%;
		padding: .3rem;
		position: relative;
		overflow: hidden;
		@include media-breakpoint-up(md) {
			width: 241px;
		}
		@media (max-width: 767px) {
			display: none;
			&:first-child {
				display: block;
				& + .swiper-slide {
					display: block;
					& + .swiper-slide {
						display: block;
						& + .swiper-slide {
							display: block;
							& + .swiper-slide {
								display: block;
								& + .swiper-slide {
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}
	.swiper-wrapper {
		box-sizing: border-box !important;
		@media (max-width: 767px) {
			padding-top: 2rem;
			transform: none !important;
			flex-wrap: wrap;
		}
	}
}
// @media all and (-ms-high-contrast:none) {
//     *::-ms-backdrop, .sm-flockler__carousel .swiper-wrapper {
// 		margin-left: 168px;
// 	}
// }
.sm-flockler__carousel-container {
	flex: 0 0 100%;
	max-width: 100%;
	overflow: hidden;
	@include media-breakpoint-up(md) {
		flex: 0 0 1438px;
		max-width: 1438px;
	}
}
.sm-flockler__link {
	position: absolute;
	bottom: .6rem;
	right: .6rem;
	color: currentColor;
	display: block;
	padding: .4rem;
	svg {
		width: 2rem;
		height: 2rem;
		display: block;
	}
	&:hover {
		svg {
			fill: white;
			fill: url(#brand-gradient);
		}
	}
}

.sm-flockler__link-container {
	width: 100%;
	overflow: hidden;
	position: relative;
	display: block;
	color: white;
	&:hover {
		.sm-flockler__image {
			transform: scale(1.06, 1.06);
		}
	}
}

.l_sm-flockler-row {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	@include media-breakpoint-up(md) {
		flex-wrap: nowrap;
	}
}
.sm-flockler__follow-us {
	a {
		color: inherit;
		text-decoration: none;
	}
	img {
		width: 23px;
		display: inline-block;
		margin-bottom: -7px;
		margin-left: 1px;
	}
}
.hidden-preload-images {
	position: absolute;
	left: -99999px;
	opacity: 0;
	pointer-events: none;
	display: none;
}


.swiper-button {
	transition: all .3s $thibault-swing;
	will-change: transform;
	&:before {
		content: '';
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		transition: all .3s $thibault-swing;
		background-color: transparent;
	}

	&:hover {
		transform: scale(1.25, 1.25);
		&:before {
			width: 26px;
			height: 26px;
			background-color: #ffffff26;
		}
	}

	&.swiper-button--dark {
		&:hover {
			&:before {
				background-color: rgba(0, 21, 50, .14);
			}
		}
	}
}

.carousel__expand {
	transition: all .3s $thibault-swing;
	will-change: transform;
	&:hover {
		transform: scale(1.25, 1.25);
	}
}



@-webkit-keyframes showPage {
	0%   {
		visibility: hidden;
	}
	100% {
		visibility: visible;
	}
}

@-moz-keyframes showPage {
	0%   {
		visibility: hidden;
	}
	100% {
		visibility: visible;
	}
}


@keyframes showPage {
	0%   {
		visibility: hidden;
	}
	100% {
		visibility: visible;
	}
}

.content-reveal,
.content-reveal-delayed {
	visibility: hidden;
}

body {
	visibility: hidden;
	animation: showPage .3s linear forwards .25s;
}

.slide-inner {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-size: cover;
	background-position: center;
}

.swiper-slide {
	overflow: hidden;
}

.error-hero {
	.content-block__height {
		min-height: 100vh;
	}
}