.animate-line {
	position: absolute;
	height: 0;
	top: 0;
	overflow: hidden;
    width: 100%;
	transform: translateZ(0);
	z-index: 0;
	pointer-events: 0;
}
.animate-line-container {
	position: absolute;
	left: 0;
	width: 100%;
	z-index: 0;
	// .animate-line__svg-container {
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	z-index: 1;
		
	// }
	// h1 {
	// 	font-size: 110px;
	// 	padding: 100px;
	// }
	// svg {
	// 	display: block;
	// 	display: none;
	// 	transform: translateZ(0);
	// 	position: relative;
    // }
}
//DOTO : This is easy but is not rendering correctly :(
.background-pattern {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-image: url('/resources/img/sample/bg-pattern2.png');
	background-position: top right;
	background-repeat: repeat-y;
	background-size: 100% auto;

	@media (max-width: 719px) {
		background-image: url('/resources/img/bg-pattern/Pattern_BG_Good_from_720.png');
	}
	@media (min-width: 720px) and (max-width: 2199px) {
		background-image: url('/resources/img/bg-pattern/Pattern_BG_Good_720_to_1440.png');
	}
	@media (min-width: 2200px) {
		background-image: url('/resources/img/bg-pattern/Pattern_BG_Good_1440_plus.png');
	}
}

.background-line-colour {
	background-color: $animate-line-colour;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	transition: height .4s linear;
	@media (min-width: 800px) {
		transition: height .65s linear;
	}
	@include media-breakpoint-up(lg) {
		transition: height .8s linear;
	}
	&:after {
		content: '';
		position: absolute;
		height: 4px;
		bottom: 0;
		left: 0;
		width: 100%;
		background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%);
	}
}