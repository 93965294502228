// $card-bg            : green;
$card-bg            : $white;
$review-padding     : 4rem;

.reviews-container {
    display: flex;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-top: 5.4rem;
    .swiper-wrapper {
        @include media-breakpoint-up(md) {
            transform: none !important;
        }
    }
}

.review {
    flex: 0 0 100%;
    max-width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: relative;
    width: 100%;
    min-height: 1px;
    @include media-breakpoint-up(md) {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .quote {
        max-width: 400px;
        margin: 0 auto;
    }

    .review__title {
        position: relative;
        padding-bottom: 2.4rem;
        &:before {
            content: '';
            display: block;
            width: 30px;
            height: 1px;
            background-color: $h-blue;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            opacity: .25;
        }
    }
}
.review__image-container {
    height: 6.4rem;
}
.review__image {
    width: 12.8rem;
    height: 12.8rem;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;

    img {
        width: 100%;
    }
}

.review__body {
    padding: 8.4rem $review-padding 0 $review-padding;
    width: 100%;
    @include media-breakpoint-up(md) {
        padding: 8.4rem 3rem 0 3rem;
    }
    @include media-breakpoint-up(lg) {
        padding: 8.4rem $review-padding 0 $review-padding;
    }
    .quote {
        margin-bottom: 0;
        padding-bottom: 1.8rem;
    }
}

.review__footer {
    padding: 1.6rem $review-padding 3rem $review-padding;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    flex: auto;
}
.review__link {
    @include font-button();
    @include font-bold();
    display: block;
    text-align: center;
    color: $tertiary;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-size: 1.2rem;
    text-decoration: none;
    // transition: all 5s ease;
    // &:hover {
    //     font-weight: 800;
    //     letter-spacing: .175em;
    // }
}

.review-bg {
    background-color: $card-bg;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 64px);
}

.review__carousel {
    width: 100%;
    padding-bottom: 8.4rem;
    @include media-breakpoint-up(md) {
        padding-bottom: 0;
    }
    .swiper-navigation {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        .swiper-button {
            margin-left: .4rem;
            margin-right: .4rem;
            position: relative;
            color: $body-colour;
            margin-top: 0;
            left: auto;
            right: auto;
            top: auto;
        }
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}