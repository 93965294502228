// Two column
.two-column {
    &.is-wide {
        .two-column__row {
            @media (min-width: 1360px) {
                margin-left: calc((8.33333% + 16px) * -1);
                margin-right: calc((8.33333% + 16px) * -1);
            }
        }
    }

    &.is-flip {
        @include media-breakpoint-up(md) {
            .col-md-6 {
                order: 1;

                & + .col-md-6 {
                    order: 0;
                }
            }
        }
    }

    &.is-align-center {
        .two-column__row {
            align-items: center;
        }
    }
}

// 
.content-block--2col {
    @include media-breakpoint-up(xl) {
        .container {
            padding-left: 7.2rem;
            padding-right: 7.2rem;
        }
    }
    & + .content-block--2col.u_padding-top-none {
        padding-top: $grid-gutter-width !important;
    }
    .col-16 + .col-16 {
        padding-top: $grid-gutter-width;
        @include media-breakpoint-up(md) {
            padding-top: 0;
        }
    }
}