.contact-banner {
    height: 374px;
    position: relative;
    background-position: center top;
    @include nav-gradient-top();
    @include media-breakpoint-up(md) {
        height: 400px;
    }
}

.contact {
    padding-top: 4.5rem;
    @include media-breakpoint-up(md) {
        padding-top: 9.6rem;
    }
}

.contact__info {
    .i-block-container {
        padding-top: 1rem;
    }
    .i-block {
        padding-top: 1.8rem;
        padding-bottom: 1.8rem;
        max-width: 376px;
    }
    &--sm {
        padding-bottom: 2.2rem;
    }
}

.contact__details {
    @include media-breakpoint-up(lg) {
        margin-left: $grid-gutter-width * -1;
        padding-right: 2.8rem;
    }
    .contact__details-copy {
        padding-top: 1.2rem;
        max-width: 477px;
        padding-right: 3.2rem;
        // Temporarily disabled uncomment if client requests signature to show on contact details.
        // background-image: url('/resources/img/contact-signature.png');
        background-repeat: no-repeat;
        background-size: 256px 43px;
        background-position: left 60px bottom;
        @include media-breakpoint-up(md) {
            padding-top: 5rem;
            margin-top: 4rem;
            border-top: 1px solid rgba(0, 0, 0, .07);
        }
        p {
            line-height: 1.928571428571429;
        }
    }
    .tiles {
        padding-top: 3.2rem;
        padding-bottom: 2rem;
        margin-left: -6px;
        margin-right: -6px;
        @include media-breakpoint-up(md) {
            padding-top: 5.3rem;
            padding-bottom: 4.6rem;
        }
    }
    .tile-container {
        padding-left: 6px;
        padding-right: 6px;
        margin-bottom: .4rem;
        @include media-breakpoint-up(lg) {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
        }
    }
    .tile {
        height: 123px;
        &:after {
            max-height: 60%;
        }
        @include media-breakpoint-up(lg) {
            height: 228px;
        }
    }
    .tile__title {
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }
    }
    .tile__body {
        @include media-breakpoint-up(md) {
            padding-left: 1.6rem;
            padding-right: 1.6rem;
            padding-bottom: 2.4rem;
        }
    }
}

/////
// Contact Form
.contact__form-title {
    @include font-light();
    display: inline-block;
    padding: .8rem 2.4rem;
    color: $light-white-text;
    background-color: $secondary;
    font-size: 1.2rem;
    letter-spacing: 0.03em;
    text-align: center;
    margin: 0;
}

.contact__form {
    padding-bottom: 5rem;
    margin-left: -15px;
    margin-right: -15px;
    @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
    }
}

.contact__form .form {
    position: relative;
    background-color: $white;
    padding: 4rem 2.2rem 8rem 2.2rem;
    border-radius: $border-radius;
    @include media-breakpoint-up(md) {
        padding: 4rem $grid-gutter-width 8rem $grid-gutter-width;
    }
    @include media-breakpoint-up(xl) {
        padding: 4rem 4.8rem 8rem 4.8rem;
    }
    .i-block {
        padding-top: 0;
        margin-top: 0;
        padding-bottom: 2.4rem;
    } 
}
.contact__form-submit {
    position: absolute;
    right: 0;
    bottom: 0;
}

.l_form-col-row {
    @include media-breakpoint-up(xl) {
        display: flex;
        align-items: flex-end;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        .l_form-col {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            flex: 0 0 50%;
            max-width: 50%;
            
        }
    }
}

.contact {
    @include media-breakpoint-up(md) {
        .contact-col--1 {
            order: 2;
        }
        .contact-col--2 {
            order: 1;
        }
    }
}


.contact__info--sm {
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.contact__info--lg {
    display: none;
    @include media-breakpoint-up(md) {
        display: block;
    }
}