$nav-colour 				: white;
$nav-colour-alt             : $body-colour;

$nav-t-speed-in             : .65s;
$nav-t-speed-out            : .65s;

$nav-transition-type        : linear;

.site-header {
    position: fixed;
    top: -1px;
    left: 0;
    right: 0;
    z-index: $site-header-index;
    z-index: 999;
    color: $nav-colour;
    background-color: transparent;
    transition: background-color $nav-t-speed-out $nav-transition-type;
    backface-visibility: hidden;
    transform: translateZ(0);
    transform-style: preserve-3d;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    .menu-is-open & {
        z-index: 999999999;
        background: linear-gradient(135deg, #001532 0%, #003255 100%);
        bottom: 0;
    }
    .country {
        width: 7.2rem;
    }
    .country__selected {
        img {
            opacity: .75;
        }
    }
    .social__link {
        &:first-child {
            margin-left: 0;
        }
    }
    &.has-bg {
        transition: background-color $nav-t-speed-in $nav-transition-type;
        background-color: $site-header-bg;
        &:before {
            opacity: 0;
        }
        .country__selected {
            img {
                opacity: 1;
            }
        }
    }
    &.show-burger {
        .site-header__navigation {
            opacity: 0;
            pointer-events: none;
        }
        .site-header__menu-btn {
            display: block;
        }
    }
    &__bar {
        position: relative;
        display: flex;
        align-items: center;
        height: $site-header-height-sm;
        padding-left: $site-padding-sm;
        padding-right: $site-padding-sm;
        @include media-breakpoint-up(md) {
            padding-left: $site-padding-md;
            padding-right: $site-padding-md;
        }
        @include media-breakpoint-up(lg) {
            height: $site-header-height-lg;
            padding-left: $site-padding-lg;
            padding-right: $site-padding-lg;
        }
    }
}

.menu-is-open {
    .site-header__bar {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
}

.navigation {
    list-style-type: none;
    padding-left: 0;
}

.navigation__link {
    text-decoration: none;
    display: block;
    &.active {
		span {
			&:before {
				width: 100%;
			}
		}
	}
}
.webcam-li {
    padding-left: 2rem;
    .site-header__menu & {
        padding-left: 3px;
    }
}
.webcam-link {
    color: $nav-colour;
    margin-right: 4.4rem;
    margin-left: -.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 1px solid rgba(255, 255, 255, .2);
    border-radius: $border-radius;
    display: block;
    padding-top: .3rem;
    padding-bottom: .3rem;
    transition: background-color .3s ease;
    &:hover {
        background-color: White;
        color: #001431;
        
    }
    .has-bg & {
        &:hover {
            background-color: #001431;
            color: white;
        }      
    }
    svg {
        width: 20px;
        height: 20px;
    }
    &.navigation__link {
        padding-left: 0;
        display: inline-block;
    }
    .has-bg & {
        color: $nav-colour-alt;
        border: 1px solid rgba(0, 21, 50, .2);
    }
}

.site-header__navigation {
    flex: 0 0 100%;
    max-width: 100%;
    .navigation {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-left: 22rem;
        li {
            display: block;
            line-height: 1;
        }
        .navigation__li--booking {
            position: absolute;
            right: 3rem
        }
    }
    .navigation__link {
        @include font-light();
        line-height: 1;
        padding-left: 3.2rem;
        padding-right: 3.2rem;
        color: $nav-colour;
        font-size: 1.7rem;
        .has-bg & {
            color: $nav-colour-alt;
        }
    }
    .navigation__li--has-submenu {
        .navigation__link {
            padding-top: 2rem;
            padding-bottom: 2rem;
            span {
                overflow: visible;
                &:before {
                    display: none;
                }
                // position: relative;
                &:after {
                    content: '';
                    display: block;
                    left: -2rem;
                    right: -2rem;
                    height: 4px;
                    background-color: #FF7747;
                    border-radius: 1px;
                    position: absolute;
                    z-index: 2;
                    bottom: -2.8rem;
                    opacity: 0;
                    transition: opacity .3s ease;
                }
            }
        }
        &.is-active-submenu {
            .navigation__link {
                span {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// Hamburger
.site-header__menu-btn {
    width: 30px;
    height: 20px;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: 0;
    outline: 0;
    right: $site-padding-sm;
    display: none;
    cursor: pointer;
    @include media-breakpoint-up(md) {
        right: $site-padding-md;
    }
    @include media-breakpoint-up(lg) {
        right: $site-padding-lg;
    }
    @include media-breakpoint-up(xxl) {
        display: none;
    }
}
.hamburger {
    position: absolute;
    top: 50%;
    right: 0;
    width: 30px;
    height: 2px;
    background: $white;
    display: block;
    -webkit-transform-origin: center;
    transform-origin: center;
    transition: all .35s ease-in-out;
    will-change: transform;
    .has-bg & {
        background: $body-colour;
    }
    
    &:before,
    &:after {
        transition: all .35s ease-in-out;
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: $white;
        will-change: transform;
        .has-bg & {
            background: $body-colour;
        }
    }
    &:before {
        top: -7px;
    }
    &:after {
        bottom: -7px;
    }
    .menu-is-open & {
        transform: rotate(45deg);
        background: $white;
        &:before {
            transform: rotate(90deg);
            top: 0;
            background: $white;
        }
        &:after {
            transform: rotate(90deg);
            bottom: 0;
            background: $white;
        }
    }
}

.menu-is-open {
    &:not(.mobile) {
        overflow: hidden;
    }
    .site-header {
        .country {
            width: auto;
        }
    }
}

.site-header__book-button {
    @include font-bold();
    font-size: 1.6rem;
    text-transform: none;
    letter-spacing: 0;
    height: 4rem;
    padding-right: 5.4rem;
    color: $nav-colour;
    transition: none;
    padding-left: 2.4rem;
    .has-bg & {
        color: $primary;
        transition: color .5s ease !important;
        &:hover,
        &:focus {
            color: $nav-colour;
        }
    }
    &:hover,
    &:focus {
        &:before {
            width: calc(100%);
        }
    }
    &:before {
        width: 4rem;
        height: 4rem;
        border-radius: 4px 4px 4px 4px !important;
        background-position: center right 1.6rem;
    }
}

// LOGO
.site-header__logo {
    position: absolute;
    left: $site-padding-sm;
    display: block;
    color: $nav-colour;
    line-height: 1;
    z-index: 20;
    @include media-breakpoint-up(md) {
        left: $site-padding-md;
    }
    @include media-breakpoint-up(lg) {
        left: $site-padding-lg;
    }
    .has-bg & {
        color: $nav-colour-alt;
    }
    
    svg {
        display: block;
        path {
            fill: currentColor !important;
        }
    }
}

// Mobile menu nav {
.site-header__menu {
    background-color: $secondary;
    background: linear-gradient(135deg, #{$gradient-colour-start} 0%,#{$gradient-colour-end} 100%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    color: $white;
    display: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    .country__list-icon {
        background-image: url("#{$dropdown-arrow-light}") !important;
    }
    .navigation {
        padding-bottom: 3.6rem;
        padding-top: calc(#{$site-header-height-sm} + 1.6rem);
        padding-left: $site-padding-sm;
        padding-right: $site-padding-sm;
        @include media-breakpoint-up(md) {
            padding-top: calc(#{$site-header-height-lg} + 1.6rem);
            padding-left: $site-padding-md;
            padding-right: $site-padding-md;
        }
        @include media-breakpoint-up(lg) {
            padding-left: $site-padding-lg;
            padding-right: $site-padding-lg;
        }
    }
    .navigation__link {
        color: $white;

    }
    .li--primary {
        .navigation__link {
            @include font-extralight();
            padding-top: 1.6rem;
            padding-bottom: 1.6rem;
            font-size: 2.4rem;
        }
    }
    .li--secondary {
        .navigation__link {
            padding-top: 1.2rem;
            padding-bottom: 1.2rem;
            font-size: 1.4rem;
        }
        &.webcam-li {
            a {
                padding-right: 2rem;
                border: 0;
                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
    .country {
        padding-top: 3.2rem;
        @include media-breakpoint-up(md) {
            margin-left: -1.1rem;
        }
        @include media-breakpoint-up(md) {
            .country__list {
                background-color: $secondary;
            }
        }
        .country__list-icon {
            background-image: url("#{$dropdown-arrow-light}");
        }
    }
    .social {
        padding-top: 3.2rem;
        @include media-breakpoint-up(md) {
            justify-content: flex-start;
            .social__link {
                margin-left: 0;
                margin-right: 1.4rem;
            }
        }
    }
}

.menu-is-open {
    .show-burger {
        .site-header__menu {
            display: block;
            display: flex;
            flex-direction: column;
            height: calc(100% - 65px);
            overflow: auto;
            top: 65px;
            .navigation {
                flex-grow: 1;
            }
            .navigation {
                padding-top: 1.6rem;
            }
        }

        .site-header__logo {
            color: $white;
        }
        .site-header__bar {
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 104px;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(to bottom,  rgba(1,31,60,1) 0%,rgba(1,31,60,1) 60%, rgba(1,31,60,.7) 75%, rgba(1,31,60,0) 100%);
                z-index: 2;
                pointer-events: none;
            }
        }
    }
}

.alternative-navigation {
        .site-header {
            background-color: white;
            .webcam-link {
                color: $nav-colour-alt;
                border: 1px solid rgba(0, 21, 50, .2);
                &:hover {
                    background-color: #001431;
                    color: white;
                }
            }
            .navigation__link {
                color: $nav-colour-alt;
            }
            .country__list-icon { 
                background-image: url("#{$dropdown-arrow-dark}");
            }
        }
        .site-header__logo {
            color: $nav-colour-alt;
        }
        
        &.menu-is-open {
            .show-burger {
                .navigation__link {
                    color: white;
                }
            }
        }

        .site-header:not(.show-burger) {
            .country__list {
                border: 1px solid #EBEBEB;
                background-color: $white;
                .country__link {
                    color: $body-colour;
                }
            }
            .country__list-icon { 
                background-image: url("#{$dropdown-arrow-dark}");
            }
            .navigation__link {
                color: $nav-colour-alt;
            }
        }

        &:not(.menu-is-open) {
            .hamburger {
                background: $body-colour;
                &:before,
                &:after{
                    background: $body-colour;
                }
            }
            .site-header:not(.show-burger) {
                .country__list {
                    border: 1px solid #EBEBEB;
                    background-color: $white;
                    .country__link {
                        color: $body-colour;
                    }
                }
                .country__list-icon { 
                    background-image: url("#{$dropdown-arrow-dark}");
                }
                .navigation__link {
                    color: $nav-colour-alt;
                }
            }
        }
    
    .site-header__book-button {
        color: $primary;
        transition: color .5s ease !important;
        &:hover,
        &:focus {
            color: $nav-colour;
        }
    }
    .main {
        padding-top: $site-header-height-sm;
        @include media-breakpoint-up(lg) {
            padding-top: $site-header-height-lg
        }
    }
}

// TODO - Refactor this with better naming etc.
.navigation__li a {
    position: relative;
    z-index: 2;
}
.submenu {
    position: absolute;
    left: 0;
    top: 0;
    background: radial-gradient(703.73% 197.53% at 12% 6.16%, #001025 0%, #003255 100%);
    height: 100vh;
    max-height: 82rem;
    overflow: auto;
    width: 100%;
    padding-top: $site-header-height-lg;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    overflow-x: hidden;
    padding-bottom: 2.4rem;
    // transition: opacity .2s ease .3s;
    .tile__link__text {
        padding-right: 1.4rem;
    }
    .li--primary & {
        z-index: 10;
        // height: 100vh;
        max-height: none;
        padding-top: 65px;
        -webkit-overflow-scrolling: touch;
        position: fixed;
        top: 0px;
        bottom: -1px;
        padding-bottom: 0;
        .submenu__title {
            font-size: 2.4rem;
        }
        .submenu__title-header {
            display: flex;
            align-items: center;
            &__back {
                padding-top: 1.2rem;
                padding-bottom: 1.2rem;
                padding-right: 1.2rem;
                cursor: pointer;
                margin-left: -3.8rem;
                svg {
                    position: relative;
                    top: -3px;
                }
            }
        }
    }
    &.is-active,
    &.is-active-clicked {
        pointer-events: auto;
        opacity: 1;
        // transition: opacity 0s ease .3s;
        .submenu__container {
            // transform: translateX(0%);
            opacity: 1;
            // transition: transform.6s ease .25s, opacity .6s ease .25s;
            transition: opacity .6s ease .25s;
        }
    }
    .desktop-submenu__close {
        display: none;
        position: absolute;
        top: 63px;
        right: 0;
        color: white;
    }
    &.is-active-clicked {
        .desktop-submenu__close {
            display: inline-flex;
        }
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: $site-header-height-lg;
        left: 0;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        z-index: 5;
        width: 100%;
        .li--primary & {
            top: 65px;
        }
    }
    .submenu__container {
        width: 100%;
        max-width: 129rem;
        padding-left: 3rem;
        padding-right: 3rem;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 2rem;
        opacity: 0;
        will-change: opacity;
        transition: transform .4s ease, opacity .4s ease;
        transition: opacity .4s ease;
        .li--primary & {
            padding-bottom: 8rem;
            height: 100%;
            overflow: auto;
        }
    }
    &__header {
        position: relative;
        padding-top: 4.8rem;
        padding-bottom: 1.2rem;
        .li--primary & {
            padding-top: 2rem;
            padding-left: 3.8rem;
        }
        &-link {
            text-decoration: none;
            color: inherit;
            &:hover {
                .tile__link__arrow {
                    transform: translateX(1.2rem);
                }
            }
        }
    }
    .submenu__tiles {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        .tile__title {
            font-size: 3.2rem;
            margin-bottom: 1.6rem;
        }
        .tile__body {
            padding-bottom: 2.8rem;
            padding-left: 2.8rem;
        }
        .tile-container {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 1.5rem;
            .li--primary & {
                @media (max-width: 768px) {
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                }
                padding-bottom: 0;
                .tile__title {
                    font-size: 2.4rem;
                    margin-bottom: 1rem;
                }
                .tile__body {
                    padding-bottom: 2rem;
                    padding-left: 2rem;
                }
            }
            .tile {
                &:before {
                    content: '';
                    display: block;
                    padding-top: 75.0425%;
                    @media (max-height: 820px) {
                        padding-top: 0;
                        height: 40rem;
                    }
                    .li--primary & {
                        padding-top: 56%;
                        @media (max-width: 540px) {
                            padding-top: 0;
                            height: 17.4rem;
                        }
                    }
                }
                &:after {
                    height: 100%;
                    background: linear-gradient(18deg, rgba(0, 10, 24, 1) 21.11%, rgba(0, 21, 50, 0.00) 57.81%);
                }
                &__body {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
                &:hover {
                    &:after {
                        opacity: .85;
                    }
                }
            }
        }
        &.count {
            &--3 {
                .tile-container {
                    flex: 0 0 33.333333%;
                    max-width: 33.333333%;
                }
                .tile {
                    &:before {
                        content: '';
                        display: block;
                        padding-top: 125%;
                        @media (max-height: 820px) {
                            padding-top: 0;
                            height: 40rem;
                        }
                        .li--primary & {
                            padding-top: 56%;
                            @media (max-width: 540px) {
                                padding-top: 0;
                                height: 17.4rem;
                            }
                        }
                    }
                }
            }
            &--3-sm {
                .tile-container {
                    flex: 0 0 33.333333%;
                    max-width: 33.333333%;
                }
                .tile {
                    &:before {
                        content: '';
                        display: block;
                        padding-top: 61.35%;
                        @media (max-height: 820px) {
                            padding-top: 0;
                            height: 17.6rem;
                        }
                        .li--primary & {
                            padding-top: 0;
                            height: 17rem;
                            @media (max-width: 540px) {
                                height: 12rem;
                            }
                        }
                    }
                }
            }
        }
    }
    &__title {
        display: block;
        @include font-bold();
        font-size: 4.4rem;
        padding-bottom: 1rem;
        &-strapline {
            @include font-light();
            color: rgba(255, 255, 255, .6);
        }
    }
    .tile__link__text {
        font-size: 1.2rem;
        line-height: 1;
        padding-top: .2rem;
    }
}
.menu-is-open .show-burger .site-header__bar:before {
    display: none;
}
.mobile-menu-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 2.6rem;
    @media (min-width: 768px) {
        padding-left: 3.6rem;
        padding-right: 3.6rem;
    }
    .button--button {
        padding-right: 5.8rem;
        padding-left: 3.2rem;
        &:before {
            width: 4rem !important;
            height: 4rem !important;
            border-radius: 5px !important;
            top: 4px;
            background-position: center right 1.7rem;
        }
    }
    .button__text {
        text-transform: none;
        color: white;
        font-size: 1.7rem;
        letter-spacing: 0;
    }
}
.site-header__menu .social {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.submenu-bg {
    background-color: rgba(0, 0, 0, .3);
    background: linear-gradient(18deg, rgba(0, 10, 24, 1) 21.11%, rgba(0, 21, 50, 0.00) 57.81%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
}
.submenu-is-open {
    .submenu-bg {
        opacity: .75;
        // transition: opacity .6s ease;
    }
    .site-header__navigation .navigation__link,
    .site-header__logo,
    .webcam-link {
        color: white !important;
        transition: none !important;
    }
    
}