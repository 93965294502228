$card-image-hover-gradient: linear-gradient(to right,  rgba(0,21,50,1) 0%,rgba(0,21,50,0.49) 100%);;

.card {
  @include card-shadow();
  position: relative;
  color: $body-colour;
  display: flex;
  height: 100%;
  flex-direction: column;
	border-radius: $border-radius;
  overflow: hidden;
  cursor: pointer;
  background-color: $white;
  text-decoration: none;
  @include hover-focus() {
      text-decoration: none;
  }

  .i-block-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: 1.4rem;
  }
  .i-block {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 0;
    margin-bottom: 0;
    @include media-breakpoint-up(sm) {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    
    @include media-breakpoint-up(md) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: 1000px) {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }

}

.card__body {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
}

.card__title {
  @include font-display-2();
  margin-bottom: 1.5rem;
  line-height: 1.33333333;
}

.card__footer {
  padding-top: .8rem;
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
}

.card-pointer{
  cursor: default !important;
}

// Two column card tiles = v1
// Room type tyles = v2

.card--v1,
.card--v2 {
  margin-left: calc((#{$grid-gutter-width} / 2) * -1);
  margin-right: calc((#{$grid-gutter-width} / 2) * -1);
  @media (min-width: 660px) {
    margin-left: 0;
    margin-right: 0;
  }
  .card__body {
    padding: 2.4rem 2.4rem 0 2.4rem;
    @include media-breakpoint-up(xl) {
      padding: 2.6rem 4rem 0 4rem;
    }
  }
}


.card--v2 {
  .card__title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
  }
  .card__carousel {
    .swiper-slide {
      @include responsive-div(52%);
    }
    .swiper-navigation ,
    .carousel__expand{
      display: none;
    }
    &.swiper-container-initialized {
      .swiper-navigation,
      .carousel__expand {
        display: block;
      }
    }
  }

  .card__content {
    text-decoration: none;
    color: $body-colour;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    &:hover {
      .button--button {
        &:before {
          width: 100%;
        }
        .button__text {
          color: white;
        }
      }
    }
  }
}

.card__price {
  white-space: nowrap;
  .card__price-text {
    @include font-regular();
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 0.21em;
    display: inline-block;
    vertical-align: baseline;
  }
  .card__price-amount {
    font-weight: 800;
    font-size: 2.2rem;
    letter-spacing: 0.05em;
    display: inline-block;
    vertical-align: baseline;
    margin-left: -2px;
  }
}
.card__price--top {
  padding-left: 2rem;
  display: none;
  @include media-breakpoint-up(sm) {
    display: block;
  }
  @include media-breakpoint-up(md) {
    display: none;
  }
  
  @media (min-width: 860px) {
    display: block;
  }
}

.card__price--bottom {
  @include media-breakpoint-up(sm) {
    display: none;
  }
  @include media-breakpoint-up(md) {
    display: block;
  }
  @media (min-width: 860px) {
    display: none;
  }
}

.card--v1 {
	.card__image {
    @include responsive-div(52%);
    overflow: hidden;
		.c_price {
			position: absolute;
			bottom: 0;
			left: 0;
    }
    .card__hover {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .card__hover-gradient {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: $card-image-hover-gradient;
        opacity: 0;
        transform: translateX(-100%);
        transition: all .4s $thibault-swing;
      }
      .card__hover-text {
        @include font-display-4();
        position: relative;
        z-index: 2;
        color: $white;
        font-size: 1.7rem !important;
        margin: 0;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
        opacity: 0;
        transition: all .25s ease;
      }
      .card__hover-button-container {
        transform: translateY(100%);
        position: absolute;
        right: 0;
        bottom: 0;
        transition: transform .35s $thibault-swing;
      }
      .card__hover-button {
        height: 4.7rem;
        .button__text {
          opacity: 0;
        }
        &:before {
          border-radius: 0;
          width: 4.7rem;
          height: 100%;
          background-position: center right 2rem;
        }
      }
    }
  }
  &:hover {
    .card__image {
      .card__hover-gradient,
      .card__hover-text {
        opacity: 1;
      }
      .card__hover-gradient {
        transform: translateX(0);
      }
      .card__hover-text {
        transition: all .75s ease .3s;
      }
      .card__hover-button-container {
        transform: translateY(0);
        transition: transform .35s $thibault-swing .25s;
      }
    }
  }
  .card__footer {
    display: none;
    .mobile & {
      display: flex;
    }
  }
}

// 
.c_price {
	background-color: $secondary;
	color: white;
  padding: .5rem;
  width: 73px;
  height: 29px;
  overflow: hidden;
	.icon-dollar  {
    position: absolute;
    top: 9px;
    left: 48px;
    width: 12px;
    height: 12px;
    opacity: .25;
    &:nth-child(2) {
      left: 36px;
    }
    &:nth-child(3) {
      left: 24px;
    }
    &:nth-child(4) {
      left: 12px;
    }
  }
  &.is-price-1 {
    .c_price__1 {
      opacity: 1;
    }
  }
  &.is-price-2 {
    .c_price__2 {
      opacity: 1;
      & ~ svg {
        opacity: 1;
      }
    }
  }
  &.is-price-3 {
    .c_price__3 {
      opacity: 1;
      & ~ svg {
        opacity: 1;
      }
    }
  }
  &.is-price-4 {
    .c_price__4 {
      opacity: 1;
      & ~ svg {
        opacity: 1;
      }
    }
  }
}


.card__body {
  .i-block__text {
    font-size: 1.245rem;
  }
}
.card__text {
  padding-bottom: 2.8rem;
  font-size: 1.245rem;
}