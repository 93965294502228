// Image carousel
.image-carousel__image {
    img {
        max-width: 100%;
    }
}

// Image
.image-container {
    img {
        max-width: 100%;
    }
}